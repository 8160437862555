import React from "react";

const ThanksForBooking = ({
  guestInfo,
  handleBooking,
  bookedData,
  selectedHour,
  formatDate,
  valueOfBookNow,
  checkoutLoading
}) => {
  console.log("Some of the guest information is here to show: ", bookedData);

  return (
    <>
      <div className="md:p-4 w-[100%] block mx-auto">
        <h1 className="font-bold text-2xl py-4 text-center">
          Your All Booking Data
        </h1>

        <div className="">
          <div className="flex justify-between items-center border-b-2 border-b-slate-400/25 p-2">
            <h2 className="font-bold text-md">First Name</h2>
            <p className="my-1 ">{guestInfo.firstName}</p>
          </div>

          <div className="flex justify-between items-center border-b-2 border-b-slate-400/25 p-2">
            <h2 className="font-bold text-md">Last Name</h2>
            <p className="my-1 ">{guestInfo.lastName}</p>
          </div>

          <div className="flex justify-between items-center border-b-2 border-b-slate-400/25 p-2">
            <h2 className="font-bold text-md">Email</h2>
            <p className="my-1 ">{guestInfo.email}</p>
          </div>

          <div className="flex justify-between items-center border-b-2 border-b-slate-400/25 p-2">
            <h2 className="font-bold text-md">Phone Number</h2>
            <p className="my-1 ">{guestInfo.phoneNumber}</p>
          </div>

          <div className="flex justify-between items-center border-b-2 border-b-slate-400/25 p-2">
            <h2 className="font-bold text-md">Selected Hours</h2>
            <p className="my-1 ">{selectedHour}</p>
          </div>

          <div className="flex justify-between items-center border-b-2 border-b-slate-400/25 p-2">
            <h2 className="font-bold text-md">Selected Date</h2>
            <p className="my-1 ">{formatDate}</p>
          </div>
          <div className="flex justify-between items-center border-b-2 border-b-slate-400/25 p-2">
            <h2 className="font-bold text-md">Total Price</h2>
            <p className="my-1 ">${valueOfBookNow?.artistData?.price * valueOfBookNow?.selectedHour}</p>
          </div>

          <button
            disabled={
              !guestInfo?.firstName ||
              !guestInfo?.lastName ||
              !guestInfo?.email ||
              !guestInfo?.phoneNumber || checkoutLoading
            }
            className={`w-10/12 p-3 mt-2 block mx-auto ${
              !guestInfo?.firstName ||
              !guestInfo?.lastName ||
              !guestInfo?.email ||
              !guestInfo?.phoneNumber || checkoutLoading
                ? "bg-purple-500/25 "
                : "bg-purple-500"
            }  text-white font-bold text-xl cursor-pointer border border-purple-500 rounded focus:outline-none focus:bg-purple-600`}
            onClick={handleBooking}
          >
          {checkoutLoading ? "Loading..":"Checkout"}
          </button>
        </div>
      </div>
    </>
  );
};

export default ThanksForBooking;
