import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const InputWithFloatingPlaceholder = ({
  label,
  value,
  onChange,
  isActive,
  onFocus,
  onBlur,
  inputName,
}) => {
  
  const inputRef = React.useRef();
  const handleClickLabel = () => {
    onFocus(inputName);
    inputRef.current.focus();
  };

  return (
    <div className="relative">
      <input
        type={inputName === "phoneNumber" ? "number" : "text"}
        className=" p-3 w-full bg-white text-gray-800 border border-gray-300 rounded focus:outline-none focus:border-blue-500 z-10 py-3 font-semibold"
        value={value}
        onChange={onChange}
        onFocus={() => onFocus(inputName)}
        onBlur={onBlur}
        ref={inputRef}
      />
      <label
        className={`absolute top-2.5 left-3 transition-transform cursor-pointer ${
          (isActive || value !== "") && "-translate-y-3 text-sm"
        } origin-0 text-lg text-gray-600 duration-300`}
        onClick={handleClickLabel}
      >
        {label}
      </label>
    </div>
  );
};

const GuestInfo = ({ onNext, setGuestInfo, guestInfo, handleBooking }) => {
  const [activeInput, setActiveInput] = useState(null);
  const navigate = useNavigate();
  const handleFocus = (inputName) => {
    setActiveInput(inputName);
  };

  const handleBlur = () => {
    setActiveInput(null);
  };

  const handleInputChange = (inputName, value) => {
    setGuestInfo((prevGuestInfo) => ({
      ...prevGuestInfo,
      [inputName]: value,
    }));
  };
  const handleContinue = () => {
    // handleBooking();
  //  navigate("/thanksforbooking")
    onNext()
  };
  return (
    <div className="space-y-6 w-full block mx-auto">
      <h1 className="text-3xl font-bold">Guest Info</h1>

      <InputWithFloatingPlaceholder
        label="First Name"
        value={guestInfo?.firstName}
        onChange={(e) => handleInputChange("firstName", e.target.value)}
        isActive={activeInput === "firstName"}
        onFocus={() => handleFocus("firstName")}
        onBlur={handleBlur}
        inputName="firstName"
      />
      <InputWithFloatingPlaceholder
        label="Last Name"
        value={guestInfo?.lastName}
        onChange={(e) => handleInputChange("lastName", e.target.value)}
        isActive={activeInput === "lastName"}
        onFocus={() => handleFocus("lastName")}
        onBlur={handleBlur}
        inputName="lastName"
      />
      <InputWithFloatingPlaceholder
        label="Email Address"
        value={guestInfo?.email}
        onChange={(e) => handleInputChange("email", e.target.value)}
        isActive={activeInput === "email"}
        onFocus={() => handleFocus("email")}
        onBlur={handleBlur}
        inputName="email"
      />
      <InputWithFloatingPlaceholder
        className="w-full"
        label="Mobile Number"
        value={guestInfo?.phoneNumber}
        onChange={(e) => handleInputChange("phoneNumber", e.target.value)}
        isActive={activeInput === "phoneNumber"}
        onFocus={() => handleFocus("phoneNumber")}
        onBlur={handleBlur}
        inputName="phoneNumber"
      />
      <button
        disabled={
          !guestInfo?.firstName ||
          !guestInfo?.lastName ||
          !guestInfo?.email ||
          !guestInfo?.phoneNumber
        }
        className={`w-full p-3 ${
          !guestInfo?.firstName ||
          !guestInfo?.lastName ||
          !guestInfo?.email ||
          !guestInfo?.phoneNumber
            ? "bg-purple-500/25 "
            : "bg-purple-500"
        }  text-white font-bold text-xl curser-pointer border border-purple-500 rounded  focus:outline-none focus:bg-purple-600`}
        onClick={handleContinue}
      >
        Next
      </button>
    </div>
  );
};

export default GuestInfo;
