import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import profile from "../../assets/images/profile.png";
import { MdModeEdit } from "react-icons/md";
import ApiService from "../api/ApiService";
import { useLocation, useNavigate } from "react-router-dom";

const DashboardProfile = ({ setShowSetting }) => {
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.app);
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  let params = new URLSearchParams(location.search);
  let code = params.get("code");

  const [artistId, setArtistId] = useState(currentUser?._id);
  useEffect(() => {
    setArtistId(currentUser?._id);
  }, [currentUser]);
  const connectAccount = async () => {
   return  console.log("conectedd ")
    try {
      const res = await ApiService.createSellerAccount(
        `create-account?userId=${artistId}`,
        {}
      );
      const stripeClientId = "ca_Mp8y1GQVgtEEQAIPtPZTMz6Ve8SmHL86";
      const accountId = res.account_id;
      const stripeConnectUrl = ` https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${stripeClientId}&scope=read_write&state=${accountId}`;
      if (res) {
        console.log("res", res);
        window.location.href = stripeConnectUrl;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (code != null) {
      setShowModal(true);
    }
  }, [code]);
  const handleAuthorizeClick = async () => {
    let code = new URLSearchParams(location.search).get("code");
    const endpoint = { userId: currentUser._id, code: code };
    try {
      const authorizeRes = await ApiService.AuthorizeSeller(endpoint);
      localStorage.setItem("userData", JSON.stringify(authorizeRes))
      navigate("/admin-artist");
    } catch (err) {
      console.log(err);
    }
    setShowModal(false);
  };

  // Helper function to render profile information
  const renderProfileInfo = (label, value) => (
    <div className="mb-6">
      <p className="text-sm font-medium text-gray-300">{label}:</p>
      <p className="text-lg font-semibold text-white">{value}</p>
    </div>
  );

  // Helper function to render address information
  const renderAddressInfo = (label, value) => (
    <div className="w-1/2 mb-6">
      <p className="text-sm font-medium text-gray-300">{label}</p>
      <p className="text-lg font-semibold text-white">{value}</p>
    </div>
  );
  const capitalizeFirstLetter = (str) =>
    str ? str.charAt(0).toUpperCase() + str.slice(1) : "";

    console.log("currentUser:",currentUser)

  return (
    <>
      <div className="w-full p-4 md:flex">
        <div className="w-full p-6 bg-gray-700 md:w-3/5 rounded-xl md:ml-4 md:mt-4 md:mb-4 md:mr-0">
          <div className="flex items-center justify-between w-full">
            <p className="py-4 text-3xl font-bold text-white">
              Profile Information
            </p>
            <div onClick={() => setShowSetting("setting")}>
              <MdModeEdit className="p-1 mt-2 text-4xl bg-gray-700 border border-white rounded-full cursor-pointer" />
            </div>
          </div>
          <div className="grid gap-6 md:grid-cols-2">
            {renderProfileInfo(
              "Full Name",
              `${capitalizeFirstLetter(
                currentUser?.firstName
              )} ${capitalizeFirstLetter(currentUser?.lastName)}`
            )}
            {renderProfileInfo("Gender", currentUser?.gender)}
            {renderProfileInfo("Phone Number", currentUser?.number)}
            {renderProfileInfo("Email", currentUser?.email)}
          </div>
          <div className="flex flex-col w-full gap-5">
            <p className="py-2 text-3xl font-medium text-white">Address</p>
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 rtl:text-right ">
                <thead className="text-xs text-gray-700 capitalize bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Street
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Street Number
                    </th>
                    <th scope="col" className="px-6 py-3">
                      City
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Zip
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border border-gray-500 ">
                    <td className="px-6 py-4 font-medium text-white whitespace-nowrap ">
                      {currentUser?.address?.street}
                    </td>
                    <td className="px-6 py-4 font-medium text-white whitespace-nowrap ">
                      {currentUser?.address?.number}
                    </td>
                    <td className="px-6 py-4 font-medium text-white whitespace-nowrap ">
                      {currentUser?.address?.city}
                    </td>
                    <td className="px-6 py-4 font-medium text-white whitespace-nowrap ">
                      {currentUser?.address?.zip}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between gap-3 p-4 pt-16 mt-4 bg-gray-700 md:w-2/5 rounded-xl md:m-4 md-mt-0 ">
          <div className="rounded-full h-[250px] w-[250px] border-white overflow-hidden">
            <img
              className="object-cover w-full h-full rounded-full"
              src={currentUser?.avatar || profile}
              alt="Rounded avatar"
            />
          </div>
          <div className="flex flex-col justify-center pb-10">
            <h5 className="mb-4 text-xl font-medium text-center">
              Connect your Stripe Account
            </h5>
            <button
            disabled={currentUser?.stripe_refresh_token}
              className={`px-6 py-3 font-bold text-white bg-purple-900 rounded-md ${!currentUser?.stripe_refresh_token && "hover:bg-purple-800"} focus:outline-none focus:ring focus:border-blue-300`}
               onClick={ connectAccount}
            >
            {currentUser?.stripe_refresh_token ? "Connected": "Connect Stripe"}
            </button>
          </div>
          {showModal && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
              <div className="bg-white p-8 rounded-md w-96">
                <p className="text-lg font-semibold mb-4 text-black">Authorization Successfully Completed</p>
                <p className="mb-4 text-black">
                  Connect your Stripe account by clicking the button below.
                </p>
                <button
                  className="px-6 py-3 block mx-auto font-bold text-white bg-purple-900 rounded-md hover:bg-purple-800 focus:outline-none focus:ring focus:border-blue-300"
                  onClick={handleAuthorizeClick}
                >
                  Authorize Stripe
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardProfile;
