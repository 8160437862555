import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Profile from "../../assets/images/profile.png";
import { current } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

const AvatarDropdown = () => {
    const dropdownRef = useRef(null);
    const navigate = useNavigate()
    const { currentUser } = useSelector((state) => state.app);
    // const profiePicUrl = currentUser?.avatar || Profile
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };
    const handleLogout = () => {
        localStorage.clear();
        window.location.reload();
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };
    const handleProfileClick = () => {
        navigate('/admin-artist')
        toggleDropdown()
    }
    const handleAdminClick = () => {
        navigate('/dashboard')
        toggleDropdown()
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <>
            <img
                id="avatarButton"
                type="button"
                data-dropdown-toggle="userDropdown"
                data-dropdown-placement="bottom-start"
                className="w-10 h-10 rounded-full cursor-pointer"
                src={currentUser?.avatar?.length > 0 ? currentUser?.avatar : Profile}
                alt="User dropdown"
                onClick={toggleDropdown}
            />
            <div
                ref={dropdownRef}
                id="userDropdown"
                className={`z-10 ${isDropdownOpen ? '' : 'hidden'} absolute top-16 right-2  bg-gray-700 divide-y divide-gray-100  shadow w-44 `}
            >
                <div className="px-4 py-3 text-sm text-white bg-gray-700 ">
                    <p className='text-lg font-bold'>{currentUser?.firstName} {currentUser?.lastName}</p>
                    <div className="font-medium truncate">{currentUser?.email}</div>
                </div>
                {currentUser?.role === "artist" ?
                    <div onClick={handleProfileClick} className="py-2 text-sm text-white bg-gray-700" aria-labelledby="avatarButton">
                        <p className="block px-4 py-2 hover:bg-gray-600 ">
                            Dashboard
                        </p>
                    </div>
                    :
                    <div onClick={handleAdminClick} className="py-2 text-sm text-white bg-gray-700" aria-labelledby="avatarButton">
                        <p className="block px-4 py-2 hover:bg-gray-600 ">
                            Dashboard
                        </p>
                    </div>}
                <div className="py-1" onClick={handleLogout}>
                    <a
                        href="#"
                        className="block px-4 py-2 text-sm text-white bg-gray-700 hover:bg-gray-600"
                    >
                        Sign out
                    </a>
                </div>
            </div>
        </>
    );
};

export default AvatarDropdown;
