import { configureStore } from '@reduxjs/toolkit'
import usersReducer from '../features/userDetailsSlice'
import serviceDetailsReducer from '../features/serviceDetailsSlice'

export const store = configureStore({

  reducer: {
    app: usersReducer,
    serviceDetails: serviceDetailsReducer,
  },
})