import React from "react";
import imggg from '../../assets/images/image-removebg-preview.png'
import imgg from '../../assets/images/image-removebg-preview (1).png'
import img from '../../assets/images/image-removebg-preview (2).png'
export const OccasionSection = () => {
  return (
    <div className=" p-4 xl:px-24 bg-darkColor h-auto md:h-[90vh]">
      <div className="flex flex-col justify-center items-center">
        <p className="text-3xl md:text-5xl lg:text-5xl xl:text-5xl my-10 xl:my-16 lg:my-5 font-bold text-center ">
        Tattoonight Styles for Every Occasion
        </p>
        <div className="flex flex-col md:flex-col lg:flex-row xl:flex-row justify-center items-start gap-2 md:p-40 lg:p-0 xl:p-0">

          <div className="flex flex-col  justify-center items-center gap-2 p-10 lg:p-6">
            <div className="w-24">
              <img src={imggg}  />
            </div>
            <p className="text-center text-xl  ">
              Happy hour is never just an hour. From basic to luxe, book where
              the night takes you.
            </p>
          </div>
          <div className="flex flex-col  justify-center items-center gap-2 p-10 lg:p-6">
            <div className="w-24 ">
            <img src={img}
             />

            </div>
            <p className="text-center text-xl ">
              Skip the commute. With a same day deal, traffic can wait.
            </p>
          </div>
          <div className="flex flex-col  justify-center items-center gap-2 p-10 lg:p-6">
            <div className="w-24">
            <img src={imggg} />
            </div>
            <p className="text-center text-xl ">
              Let's have a threeday. Score weekend getaways in cities you love.
            </p>
          </div>
          <div className="flex flex-col  justify-center items-center gap-2 p-10 lg:p-6">
            <div className="w-24">
            <img src={img}/>

            </div>
            <p className="text-center text-xl  ">
              Go big on your bucket list. Book in advance. Zero stress.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
