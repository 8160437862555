import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ArtistCard from "../components/artistCard/ArtistCard";
import { Home } from "../components/home/Home";
import Dashboard from "../components/dashboard/Dashboard";
import BookNow from "../components/Booking/BookNow";
import { ArtistBooking } from "../components/artistBooking/ArtistBooking";
import { ArtistProfile } from "../components/artistDashboard/ArtistProfile";
import { useSelector } from "react-redux";
import ProtectedRoute, {
  PrivateOutlet,
} from "./protectedRoute/ProtectedRoutes";
import { Profile } from "../components/profile/Profile";
import ThanksForBooking from "../components/Booking/BookingSteps/ThanksForBooking";
import SellerAuthorization from "../components/sellerAuthorization/SellerAuthorization";

const Router = () => {
  const navigate = useNavigate("");
  const [currentU, setCurrentU] = useState("");
  const {loading} = useSelector(
    (state) => state.app
  );

  if (loading) {
    return (
      <div className="flex h-[100vh] justify-center items-center">
        {/* <CircularProgress /> */}
        Loading...
      </div>
    );
  }
  let userData = JSON.parse(localStorage.getItem("userData"));
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/artistcard" element={<ArtistCard />} />
        <Route exact path="/booknow" element={<BookNow />} />
        <Route exact path="/artist/:name" element={<ArtistBooking />} />

        <Route
          element={
            <PrivateOutlet
              redirect="/"
              isAuthentic={!!userData && userData?.user?.role == "admin"}
            />
          }
        >
          <Route exact path={"/dashboard"} element={<Dashboard />} />
          <Route exact path="/profile" element={<Profile />} />
        </Route>
        <Route
          element={
            <PrivateOutlet
              redirect="/"
              isAuthentic={!!userData && userData?.user?.role == "artist"}
            />
          }
        >
          <Route path={"/admin-artist"} element={<ArtistProfile />} />
          {/* <Route path={"/authorization"} element={<SellerAuthorization />} /> */}
          {/* <Route path={"/thanksforbooking"} element={<ThanksForBooking />} /> */}
        </Route>
      </Routes>
    </>
  );
};

export default Router;
