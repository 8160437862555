import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CardPayment from './CardPayment';

const PUBLIC_KEY = "pk_test_51OCct5ASJXgWgMQIYVkriTKmyGjq0rHUbHg2l3a8TILXQ1z2tqCdTW6oSlzrzIXmnnqjssKhoUoJmNnhM8BC7Yki00oI5SjxRG";
const stripePromise = loadStripe(PUBLIC_KEY);

const StripeContainer = () => {
  return (
    <Elements stripe={stripePromise}>
      <CardPayment />
    </Elements>
  );
};

export default StripeContainer;