import React, { useEffect, useState } from "react";
import ApiService from "../api/ApiService";
import ApproveModal from "./EditModal/ApproveModal";
import Modal from "./Modal";
import { FcApproval } from "react-icons/fc";
import { useNavigate } from "react-router-dom";

const ServiceTable = ({ id, changeTable }) => {
  const [apidata, setApiData] = useState([]);
  const [someFind, setsomeFind] = useState();
  const [viewData, setViewData] = useState(false);
  const [handleUpdate, setHandleUpdate] = useState(false);
  const navigate = useNavigate();

  const handleServiceCall = () => {
    const endpoint = "services";
    ApiService.fetchServiceData(endpoint).then((res) => {
      if (changeTable === "Approve Service") {
        const filterdata = res.filter((item) => item.isActive === true);
        setApiData(filterdata);
      } else if (changeTable === "Pending Service") {
        const filterdata = res.filter((item) => item.isActive === false);
        setApiData(filterdata);
      }
      console.log("response data showing here ", res);
    });
  };
  useEffect(() => {
    handleServiceCall();
  }, [changeTable, handleUpdate]);

  const handleApprove = (id, name) => {
    const data = apidata.find((item) => item._id === id);
    data.status = name;
    console.log("handle appprove id", data);
    setsomeFind(data);
    setViewData(true);
  };

  const closeModal = () => {
    setViewData(false);
  };

  const handleclick = () => {
    // if (someFind?.status == "Approve") {
    //   let activeData = {
    //     isActive: true,
    //   };
    //   let idpoint = `service/${someFind._id}`;
    //   ApiService.updateServiceData(idpoint, activeData).then((res) => {
    //     console.log("The service data response are shown here : ", res);
    //     if (res) {
    //       setViewData(false);
    //     }
    //   });
    // } else if (someFind?.status == "Inactive") {
    //   let activeData = {
    //     isActive: false,
    //   };
  //  return  console.log(" same form : ", someFind)
    let idpoint = `service/${someFind._id}`;
    ApiService.updateServiceData(idpoint, {
      isActive: !someFind.isActive,
    }).then((res) => {
      setViewData(false);
      setHandleUpdate(!handleUpdate);
    });
    // }
    // handleServiceCall()
  };
  const handleInactive = (id, name) => {
    const data = apidata.find((item) => item._id === id);
    data.status = name;
    setsomeFind(data);
    setViewData(true);
  };
  console.log("some find id is here to show data :",someFind);
  return (
    <>
      <table className="table-auto sm:table-auto w-full border-collapse border border-slate-500">
        <thead>
          <tr>
            <th className="text-start border p-1 border-slate-600">No</th>
            <th className="text-start border p-1 border-slate-600">
              FirstName
            </th>
            <th className="text-start border p-1 border-slate-600">LastName</th>
            <th className="text-start border p-1 border-slate-600">Number</th>
            <th className="text-start border p-1 border-slate-600">Email</th>
            <th className="text-start border p-1 border-slate-600">Edit</th>
            <th className="text-start border p-1 border-slate-600">view</th>
          </tr>
        </thead>
        <tbody>
          {apidata?.map((item, index) => {
            return (
              <tr key={index}>
                <td className="border border-slate-600  p-1">{index + 1}</td>
                <td className="border border-slate-600  p-1">
                  {item.userId.firstName}
                </td>
                <td className="border border-slate-600  p-1">
                  {item.userId.lastName}
                </td>
                <td className="border border-slate-600 p-1">
                  {item.userId.number}
                </td>
                <td className="border border-slate-600 p-1">
                  {item.userId.email}
                </td>
                <td className="border border-slate-600 p-1">
                  {/* <button
                    className="text-sm w-full md:w-auto mb-1 md:mb-0 border border-gray-400 px-1 py-1"
                    //   onClick={() => handleEdit(item._id, "edit")}
                  >
                    Edit
                  </button> */}
                  {id === "pending" ? (
                    <button
                      onClick={() => handleApprove(item._id, "Approve")}
                      className="text-sm w-full md:w-auto  border bg-purple-500 hover:bg-purple-700 border-gray-400 px-1 py-1 md:ml-1"
                    >
                      Approve
                    </button>
                  ) : (
                    <button
                      onClick={() => handleInactive(item._id, "Inactive")}
                      className="text-sm w-full md:w-auto  border bg-purple-500 hover:bg-purple-700 border-gray-400 px-1 py-1 md:ml-1"
                    >
                      Inactive
                    </button>
                  )}
                </td>
                <td
                  className="border border-slate-600 p-1"
                  // onClick={() => handleViewImage(item._id)}
                  onClick={() => navigate("/profile", { state: item })}
                >
                  <button className="text-sm border border-slate-400 p-1 w-full">
                    View Profile{" "}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Modal isOpen={viewData} closeModal={closeModal}>
        <div className="p-2">
          <h3 className="text-center text-6xl text-purple-500 font-bold my-2 flex items-center justify-center">
            {" "}
            <FcApproval />
          </h3>
          <h2 className="text-xl text-center text-black">
            Are you sure want to {someFind?.status}
          </h2>
          <div className="flex justify-end my-4">
            <button
              onClick={handleclick}
              className="bg-purple-500 rounded-md  text-md px-2 py-1 ml-2 my-2"
            >
              Yes
            </button>
            <button
              onClick={closeModal}
              className="bg-purple-500  rounded-md text-md px-2 py-1 ml-2 my-2"
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ServiceTable;
