import React, { useState } from "react";
import TableData from "./TableData";
import { MdAdminPanelSettings } from "react-icons/md";
import { BiSolidUserCheck } from "react-icons/bi";
import { MdPendingActions,MdApproval } from "react-icons/md";
import ServiceTable from "./ServiceTable";
const Dashboard = () => {
  const [changeTable, setChangeTable] = useState("Approved Artists");
  return (
    <>
      <div className="flex">
        <div className="w-[60px] sm:w-[200px] border-r-[1px] border-r-gray-700 md:h-[79vh] ">
          <div className="flex justify-center items-center font-semibold p-2">
            <span className="sm:block hidden"> Logo</span>
          </div>
          <ul className="p-2">
            <li onClick={() => setChangeTable("Approved Artists")}>
              <p
                className={` ${
                  changeTable === "Approved Artists"
                    ? "border-b-2 border-b-purple-500"
                    : "border-b-transparent"
                } py-1    border-b-2 flex  items-center text-sm mb-2 px-2 cursor-pointer rounded bg-slate-700 hover:bg-slate-800`}
              >
                <MdAdminPanelSettings className=" text-xl mr-1" />{" "}
                <span className="sm:block hidden">Approved Artists</span>
              </p>
            </li>
            <li onClick={() => setChangeTable("Pending Artists")}>
              <p
                className={` ${
                  changeTable === "Pending Artists"
                    ? "border-b-2 border-b-purple-500"
                    : "border-b-transparent "
                } py-1   border-b-2 flex items-center text-sm mb-2 px-2 cursor-pointer rounded bg-slate-700 hover:bg-slate-800`}
              >
                <BiSolidUserCheck className="mr-1 text-xl " />
                <span className="sm:block hidden"> Pending Artists</span>
              </p>
            </li>
            <li onClick={() => setChangeTable("Approve Service")}>
              <p
                className={` ${
                  changeTable === "Approve Service"
                    ? "border-b-2 border-b-purple-500"
                    : "border-b-transparent "
                } py-1   border-b-2 flex items-center text-sm mb-2 px-2 cursor-pointer rounded bg-slate-700 hover:bg-slate-800`}
              >
                <MdApproval className="mr-1 text-xl " />
                <span className="sm:block hidden"> Approve Service</span>
              </p>
            </li>
            <li onClick={() => setChangeTable("Pending Service")}>
              <p
                className={` ${
                  changeTable === "Pending Service"
                    ? "border-b-2 border-b-purple-500"
                    : "border-b-transparent "
                } py-1   border-b-2 flex items-center text-sm mb-2 px-2 cursor-pointer rounded bg-slate-700 hover:bg-slate-800`}
              >
                <MdPendingActions className="mr-1 text-xl " />
                <span className="sm:block hidden"> Pending Service</span>
              </p>
            </li>
          </ul>
        </div>
        <div className="w-full p-2 min-h-[79vh]  md:h-[79vh] overflow-y-scroll">
          <div className="flex justify-center items-center font-semibold p-2">
            {changeTable === "Approved Artists" && "Artist Table"}
            {changeTable === "Pending Artists" && "Artist Pending Table"}
            {changeTable === "Pending Service" && "Pending Service Table"}
            {changeTable === "Approve Service" && "Approve Service Table"}
          </div>
          {changeTable === "Approved Artists" && (
            <TableData changeTable={changeTable} />
          )}
          {changeTable === "Pending Artists" && (
            <TableData changeTable={changeTable} id="pending" />
          )}
          {changeTable === "Pending Service" && (
            <ServiceTable changeTable={changeTable} id="pending" />
          )}
          {changeTable === "Approve Service" && (
            <ServiceTable changeTable={changeTable} id="approve" />
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
