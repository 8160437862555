import React, { useState } from 'react'
import DashboardSetting from './DashboardSetting';
import DashboardProfile from './DashboardProfile';

export const ArtistDashboard = () => {
    const [showSetting, setShowSetting] = useState("dashboard");
    return (
        <>
            {showSetting == "setting" ? (
                <DashboardSetting showSetting={showSetting} setShowSetting={setShowSetting}/>
            ) : showSetting == "dashboard" ? (
                <DashboardProfile showSetting={showSetting} setShowSetting={setShowSetting}/>) : null
            }
        </>
    )
}
