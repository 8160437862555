import StartToastifyInstance from "toastify-js";
import "toastify-js/src/toastify.css"

export const ShowSuccessToast = (message) => {
    StartToastifyInstance({
        text: message,
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "left",
        stopOnFocus: true,
        style: {
            background:"#581c87"
        },
    }).showToast();
};

export const ShowErrorToast = (message) => {
    StartToastifyInstance({
        text: message,
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "left",
        stopOnFocus: true,
        style: {
            background:"red"
        },
    }).showToast();
};

