import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export const CarouselComponent = ({ modal, artistData }) => {
  const isModal = modal === "modal";
  const responsive = isModal
    ? {
        superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 1 },
        desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
        tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
        mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
      }
    : {
        superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
        desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
        tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
        mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
      };

  return (
    <Carousel responsive={responsive} className="">
      {artistData?.portfolioImages?.length > 0 &&
        artistData?.portfolioImages.map((portfolio, index) => (
       <div key={index} className="ml-2">
            <img
              key={index}
              src={portfolio}
              alt={`carousel-item-${index}`}
              className={`w-full ${
                modal === "modal" ? "h-[400px]" : "h-[200px]"
              } object-cover ml-5`}
            />
          </div>
        ))}
    </Carousel>
  );
};
