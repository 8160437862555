// import React, { useEffect, useState } from "react";
// import Modal from "../Modal";

// const EditModal = ({
//   isModalOpen,
//   closeModal,
//   valueUpdate,
//   setValueUpdate,
//   updateFunction,
// }) => {
//   const [value, setValue] = useState(
//     {
//     _id : "",
//     firstName: "",
//     lastName: "",
//     number: "",
//   });
//   // console.log("the value update is here to show : ", value);
//   useEffect(() => {
//     if (!value.firstName) {
//       setValue({
//         _id: valueUpdate[0]?._id,
//         firstName: valueUpdate[0]?.firstName,
//         lastName: valueUpdate[0]?.lastName,
//         number: valueUpdate[0]?.number,
//       });
//     }
//   }, [valueUpdate]);
//   return (
//     <>
//       <Modal isOpen={isModalOpen} closeModal={closeModal}>
//         <div className="md:w-[300px] w-[280px]">
//           <h1 className="text-black font-semibold text-center text-xl">
//             Update Data
//           </h1>
//           <label className="text-black text-sm ml-1"> Id</label>
//           <input
//             type="text"
//             disabled
//             placeholder="Id"
//             value={valueUpdate[0]?._id}
//             onChange={(e) => {
//               setValueUpdate({ ...valueUpdate[0], _id: e.target.value });
//               setValue({ ...value, _id: e.target.value });
//             }}
//             className="mb-1 text-black text-sm w-full px-1 py-2 rounded-sm border border-gray-400 outline-none"
//           />
//           <label className="text-black text-sm ml-1"> Name</label>
//           <input
//             type="text"
//             placeholder="Name"
//             value={valueUpdate[0]?.firstName}
//             onChange={(e) => {
//               setValueUpdate({ ...valueUpdate[0], firstName: e.target.value });
//               setValue({ ...value, firstName: e.target.value });
//             }}
//             className="mb-1 text-black text-sm w-full px-1 py-2 rounded-sm border border-gray-400 outline-none"
//           />
//           <label className="text-black text-sm ml-1"> Number</label>
//           <input
//             type="text"
//             placeholder="Number"
//             value={valueUpdate[0]?.number}
//             onChange={(e) => {
//               setValueUpdate({ ...valueUpdate[0], number: e.target.value });
//               setValue({ ...value, number: e.target.value });
//             }}
//             className="mb-1 text-black text-sm w-full px-1 py-2 rounded-sm border border-gray-400 outline-none"
//           />
//           <label className="text-black text-sm ml-1"> Artist</label>
//           <input
//             type="text"
//             placeholder="Artist"
//             value={valueUpdate[0]?.lastName}
//             onChange={(e) => {
//               setValueUpdate({ ...valueUpdate[0], lastName: e.target.value });
//               setValue({ ...value, lastName: e.target.value });
//             }}
//             className="mb-1 text-black text-sm w-full px-1 py-2 rounded-sm border border-gray-400 outline-none"
//           />
//           <div className="flex justify-end">
//             <button
//               onClick={() => updateFunction(value._id , value)}
//               className="border border-gray-400 bg-purple-500 hover:bg-purple-700 py-1 px-2 rounded-md my-2 text-sm mr-[2px]  "
//             >
//               Update
//             </button>
//             <button
//               onClick={closeModal}
//               className="border border-gray-400 bg-purple-500 hover:bg-purple-700 py-1 px-2 rounded-md my-2 text-sm  "
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default EditModal;
import React, { useEffect, useState } from "react";
import Modal from "../Modal";

const EditModal = ({
  isModalOpen,
  closeModal,
  valueUpdate,
  setValueUpdate,
  updateFunction,
}) => {
  const [formData, setFormData] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    number: "",
  });

  useEffect(() => {
    if (valueUpdate[0]) {
      setFormData({
        _id: valueUpdate[0]._id,
        firstName: valueUpdate[0].firstName,
        lastName: valueUpdate[0].lastName,
        number: valueUpdate[0].number,
      });
    }
  }, [valueUpdate]);

  const handleInputChange = (field, e) => {
    setFormData({ ...formData, [field]: e.target.value });
    setValueUpdate({ ...valueUpdate[0], [field]: e.target.value });
  };

  return (
    <>
      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <div className="md:w-[300px] w-[280px]">
          <h1 className="text-black font-semibold text-center text-xl">
            Update Data
          </h1>
          <label className="text-black text-sm ml-1"> Id</label>
          <input
            type="text"
            disabled
            placeholder="Id"
            value={formData._id}
            className="mb-1 text-black text-sm w-full px-1 py-2 rounded-sm border border-gray-400 outline-none"
          />
          <label className="text-black text-sm ml-1"> Name</label>
          <input
            type="text"
            placeholder="Name"
            value={formData.firstName}
            onChange={(e) => handleInputChange("firstName", e)}
            className="mb-1 text-black text-sm w-full px-1 py-2 rounded-sm border border-gray-400 outline-none"
          />
          <label className="text-black text-sm ml-1"> Number</label>
          <label className="text-black text-sm ml-1"> Number</label>
          <input
            type="text"
            placeholder="Number"
            value={formData.number}
            onChange={(e) => handleInputChange("number", e)}
            className="mb-1 text-black text-sm w-full px-1 py-2 rounded-sm border border-gray-400 outline-none"
          />

          <label className="text-black text-sm ml-1"> Artist</label>
          <input
            type="text"
            placeholder="Artist"
            value={formData.lastName}
            onChange={(e) => handleInputChange("lastName", e)}
            className="mb-1 text-black text-sm w-full px-1 py-2 rounded-sm border border-gray-400 outline-none"
          />

          {/* Repeat similar blocks for other input fields */}
          <div className="flex justify-end">
            <button
              onClick={() => updateFunction(formData._id, formData)}
              className="border border-gray-400 bg-purple-500 hover:bg-purple-700 py-1 px-2 rounded-md my-2 text-sm mr-[2px]"
            >
              Update
            </button>
            <button
              onClick={closeModal}
              className="border border-gray-400 bg-purple-500 hover:bg-purple-700 py-1 px-2 rounded-md my-2 text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditModal;
