import React from "react";

import { AiFillLike } from "react-icons/ai";
import { BsQuestionCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import noimage from "../../assets/images/profile.png";
const Cards = ({ element, index, selectedOption }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        key={index}
        onClick={() => navigate(`/artist/${element._id}`)}
        className=" bg-[#2A2B31] hover:bg-gray-800 cursor-pointer rounded-md overflow-hidden mx-2 my-4 h-[auto]"
      >
        <div className="sm:flex ">
          {/* <figure className="md:w-[500px] md:h-[185px] relative overflow-hidden">
            <img
              src={element?.userId?.avatar}
              className="w-full h-full object-cover"
              alt="Artist Image"
            />
          </figure> */}
          <div className="md:w-[30%] ">
            <img
              src={element?.userId?.avatar || noimage}
              className="w-[200px] border-r-4 block m-auto border-r-purple-500 h-[200px] object-cover p-2 rounded-full"
              alt="Artist Image"
            />
          </div>
          <div className="px-4 py-2 sm:w-[70%]">
            <div className=" mt-4">
              <div className="flex justify-between items-center">
                <h3 className="w-fit px-2 text-xs py-1 bg-purple-600 shadow-md">
                  {/* {element.hp} */} hp
                </h3>
                {/* <h3 className="w-fit px-2 text-xs py-1 bg-purple-600 shadow-md">
                Total Hours :  {element.hours_perday}
                </h3> */}
              </div>
              <div className="flex my-2 justify-between items-center">
                <h3 className="w-fit  font-semibold text-md md:text-2xl py-1 ">
                  {element?.userId?.firstName} {element?.userId?.lastName}
                </h3>
                <div className="flex">
                  <h3 className="w-fit  py-1 pr-2 font-bold text-2xl text-purple-400 shadow-md">
                    Hourly Price
                  </h3>
                  <h3 className="w-fit  py-1 font-bold text-2xl text-purple-400 shadow-md">
                    ${element?.price}
                  </h3>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="w-fit px-2 py-1 flex items-center text-gray-500 font-semibold  ">
                  <span className="flex items-center cursor-pointer  text-gray-300 font-semibold border-b-2 border-b-gray-400 pb-2">
                    <AiFillLike className="mr-2  text-gray-300 font-semibold" />
                    {element?.ratingPercent}%
                  </span>
                  {/* <span className="text-sm border-l-2 ml-2 mb-2 text-gray-300 font-semibold pl-2 border-gray-500">
                    {element.name}
                  </span> */}
                </div>
                <h3 className="w-fit  py-1 text-sm text-gray-400 font-bold flex items-center">
                  {/* {element.htrate} */}ht rate
                  <BsQuestionCircle className="ml-2 text-gray-500" />
                </h3>
              </div>
              {/* <div className="flex justify-between items-center">
                <h3 className="w-fit  font-semibold text-md py-1 ">
                  {element.location}
                </h3>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
