import React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

export const Profile = () => {
    const location = useLocation();
    const userData = location.state;
    console.log("the location of uselocation data is here to show", userData);

    const bgImageStyle = {
        backgroundImage: `url('https://miro.medium.com/v2/resize:fit:1200/1*0gSFi9FnjmRV6ti9w2Dv9Q.jpeg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };
    function renderTableInfo(label, data) {
        const dataArray = data.length > 0 ? data[0].split(',') : [];
        return (
            <div>
                <div className="relative overflow-x-auto">
                    {dataArray.map((item, index) => (
                        // <tr key={index} className="border-b ">
                        //   <td className="px-6 py-4 font-medium text-white whitespace-nowrap ">
                        //     {item.trim()}
                        //   </td>
                        // </tr>
                        <li key={index} class="flex items-center space-x-3 rtl:space-x-reverse">
                            <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
                            </svg>
                            <span>
                                {item.trim()}
                            </span>
                        </li>
                    ))}
                </div>
            </div>
        );
    }
    return (
        <>
            <section className="bg-gray-900" style={bgImageStyle}>
                <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                    <div className="mr-auto place-self-center lg:col-span-7">
                        <p className="text-4xl py-5 capitalize">
                            {userData?.userId?.firstName} {userData?.userId?.lastName}
                        </p>
                        <p className="text-2xl">Tatto Artist</p>
                    </div>
                </div>
            </section>
            <section className="bg-gray-900">
                <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                    <div className="mr-auto place-self-center lg:col-span-7 h-full">
                        <div className="w-[90%]">
                            <p className="max-w-2xl mb-4 text-2xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-4xl">
                                Bio
                            </p>
                            <p href="#" className="py-3 text-xl leading-9">
                                {userData?.about}
                            </p>
                            <div className="flex flex-col justify-start py-5 text-xl">
                                <div className="flex">
                                    <p className="py-3 font-semibold">Contact Number:</p>
                                    <p className="py-3 pl-5">{userData?.userId?.number}</p>
                                </div>
                                <div className="flex">
                                    <p className="py-3 font-semibold">Specialities:</p>
                                    <p className="py-3 pl-5">
                                        <ul class="space-y-4 text-left text-gray-500 dark:text-gray-400">
                                            {renderTableInfo("Unavailable Days", userData?.specialities.length > 0 ? userData?.specialities : "not added")}
                                        </ul>
                                    </p>

                                </div>
                                <div className="flex py-6" >
                                    <div className="relative">
                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                                            <thead className="text-xs text-gray-700 capitalize bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3">
                                                        Street
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Street Number
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        City
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Zip
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className=" border border-gray-500">
                                                    <td className="px-6 py-4 font-medium text-white whitespace-nowrap ">
                                                        {userData?.userId?.address?.street}
                                                    </td>
                                                    <td className="px-6 py-4 font-medium text-white whitespace-nowrap ">
                                                        {userData?.userId?.address?.number}
                                                    </td>
                                                    <td className="px-6 py-4 font-medium text-white whitespace-nowrap ">
                                                        {userData?.userId?.address?.city}
                                                    </td>
                                                    <td className="px-6 py-4 font-medium text-white whitespace-nowrap ">
                                                        {userData?.userId?.address?.zip}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="hidden lg:mt-0 lg:col-span-5 lg:flex h-full">
                        <img src={userData?.userId?.avatar} alt="mockup" />
                    </div>
                </div>
            </section>
            <section className="bg-gray-900 p-10">
                {/* <div className="grid max-w-screen-xl  border-t border-gray-300 px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12"> */}
                <p className="text-5xl text-center capitalize">
                    Browse {userData?.userId?.firstName} {userData?.userId?.lastName} Work
                </p>
                <p className="text-2xl text-center py-7 text-gray-400 tracking-wide">
                    VIEW ALL
                </p>

                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 py-10 ">
                    {userData?.portfolioImages.map((ele, i) => {
                        return (
                            <div key={i} className="relative group">
                                <img
                                    className="h-auto max-w-full rounded-lg transition-transform duration-300 transform group-hover:scale-110"
                                    src={ele}
                                    alt="Porfolio Images"
                                />
                            </div>
                        );
                    })}
                </div>
            </section>
        </>
    );
};
