import React, { useState } from "react";

const SwitchButton = () => {
  const [checked, setChecked] = useState(false);

  const handleToggle = () => {
    setChecked(!checked);
  };

  return (
    <label className="relative inline-block w-10 h-6">
      <input
        type="checkbox"
        checked={checked}
        onChange={handleToggle}
        className="hidden"
      />
      <div className="toggle-container bg-transparent cursor-pointer border border-stone-500 w-10 h-6 rounded-full"></div>
      <div
        className={`toggle-button absolute top-[2px] 
        cursor-pointer w-5 h-5 bg-[#C0C0C2] rounded-full shadow-md
        transform transition-transform ${
          checked ? "translate-x-[17px] bg-purple-700" : "bg-[#C0C0C2]"
        }`}
      ></div>
    </label>
  );
};

export default SwitchButton;
