import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { AiTwotoneLike, AiFillCar } from "react-icons/ai";
import { FaHotel, FaStarOfLife, FaWifi } from "react-icons/fa";
import { PiBarbellFill } from "react-icons/pi";
import { BsLaptopFill } from "react-icons/bs";
import { MdFreeBreakfast, MdDeck, MdPets } from "react-icons/md";
import { BiSolidDrink } from "react-icons/bi";
import { PiForkKnife } from "react-icons/pi";
import { CarouselComponent } from "../carousel/Carousel";
import LocationMap from "../artistCard/LocationMap";
import ImageModal from "./ImageModal";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ApiService from "../api/ApiService";
import { RatingStars } from "../ratingStars/RatingStars";
import Profile from '../../assets/images/profile.png'

export const ArtistBooking = () => {
  const { name } = useParams();
  const navigate = useNavigate("");
  const [selectedHour, setSelectedHour] = useState(null);
  const [artistData, setArtistData] = useState(null);
  const [dateRange, setDateRange] = useState();
  const [hoursValue, setHoursValue] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [navigateValue, setNavigateValue] = useState();
  const [serviceData, setServiceData] = useState([]);
  const [isDateUnavailable, setIsDateUnavailable] = useState(false);
  const [unAvailableDayss, setUnAvailableDayss] = useState([]);
  const facilities = artistData?.facilities[0];
  const totalHours = artistData?.hours_perday;

  const data2 = artistData?.unAvailableDates.join(",");
  const arrayUnavailabelDates = data2?.split(",");

  useEffect(() => {
    if (artistData && artistData.unAvailableDays) {
      const days2 = artistData.unAvailableDays.join(",");
      const arrayUnavailabelDays = days2.split(",");
      setUnAvailableDayss(arrayUnavailabelDays);
    }
  }, [artistData]);
  const filterDate = (date) => {
    // Check if the day of the week is in the unAvailableDays array
    const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
    return !unAvailableDayss?.includes(dayOfWeek);
  };
  useEffect(() => {
    const data = "services";
    ApiService.fetchServiceData(data).then((resp) => {
      setServiceData(resp);
      const foundData = resp.find((item) => item._id === name);
      if (foundData) {
        setArtistData(foundData);
      } else {
        console.error(`Artist with id ${name} not found`);
      }
    });
    setHoursValue(artistData?.hours_perday);
  }, []);
  const handleDateChange = (val) => {
    setNavigateValue(val);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleHourChange = (event) => {
    if (event && event.target) {
      const selectedHourValue = event.target.value;
      setSelectedHour(selectedHourValue);
    }
  };
  console.log(
    "artistData : @",artistData
  )
  const handleFunction = (val) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const dateValue = val && val.toLocaleDateString("en-GB", options);
    let findDate = artistData?.bookDates?.find(
      (item) => item.date == dateValue
    );
    let findDateParse = parseInt(findDate?.value);
    console.log("the find date parsede the value :", findDateParse);
    findDateParse || findDateParse == 0
      ? setHoursValue(findDateParse)
      : setHoursValue(totalHours);
    setSelectedHour(1);

    const isDateUnavailable = artistData?.unAvailableDates.includes(dateValue);
    setIsDateUnavailable(isDateUnavailable);

    console.log(
      "the un availabel dates ",
      arrayUnavailabelDates,
      "the datesss picker ",
      dateValue
    );
  };

  console.log("service Data", artistData);
  console.log("review Data", artistData?.reviewInfo);
  return (
    <div className="w-full">
      <ImageModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        artistData={artistData}
      />
      <header className="my-0">
        <div
          onClick={openModal}
          className="w-full h-[80vh] flex flex-col items-start justify-between relative"
        >
          <img
            src={artistData?.portfolioImages[0]}
            className="w-full h-full object-cover"
            alt=""
          />
          <div className="flex cursor-pointer items-start justify-between flex-col absolute w-full h-full">
            <div className="flex items-center justify-start w-full h-auto bg-gray-900 bg-opacity-50 p-2 cursor-pointer">
              <IoIosArrowBack />
              <p className="text-sm md:text-lg font-semibold pl-2">
                {artistData?.title}
              </p>
            </div>
            <div className="p-5">
              <p className="text-4xl md:text-5xl py-2 font-semibold ">
                {artistData?.title}
              </p>
              <div className="flex gap-4">
                <span className="text-lg font-semibold rounded-sm p-1 bg-purple-900">
                  Per Hour
                </span>
                <p className="text-3xl font-bold text-center ">
                  ${artistData?.price}
                </p>
              </div>

              <p className="text-xl md:text-2xl py-2 font-semibold ">
                Address:{" "}
                <span style={{ filter: "blur(3px)" }}>
                  {artistData?.userId?.address?.street},{" "}
                  {artistData?.userId?.address?.number},{" "}
                  {artistData?.userId?.address?.city},{" "}
                  {artistData?.userId?.address?.zip}
                </span>
              </p>
              <p className="text-sm md:text-lg text-bold flex items-center">
                <AiTwotoneLike />
                <span className="p-1 md:px-2">
                  {artistData?.ratingPercent} ({artistData?.reviewInfo?.length } Reviews)
                </span>
                |
                <span className="p-1 md:px-2">
                  {/* New York City - Hell's Kitchen */}
                </span>
              </p>
            </div>
          </div>
        </div>
      </header>
      <div className="md:flex w-full relative ">
        <div className="w-full md:w-2/3 flex flex-col md:m-2 md:my-2 bg-slate-900 md:rounded-lg">
          <div className="gap-1 p-6">
            <p className="text-2xl font-bold py-3">Why We Like It</p>
            <p className="py-3 text-lg">
              Fun fact: home to the largest hotel terrace in NYC – the perfect
              place to work out your sightseeing game plan (so much is nearby!).
            </p>
            <p className="py-3 text-lg">
              Super trendy and high-tech, from the smartly designed compact
              ‘cabins’ with fast free WiFi to the mobile concierge app.
            </p>
            <p className="py-3 text-lg">
              Just 2 blocks west of Times Square – plenty of time to prove you
              know all the words to 'Popular' on your way home after the show.
            </p>
          </div>
          <div className="p-6 ">
            <CarouselComponent modal="modal" artistData={artistData} />
          </div>
          {facilities?.length > 0 ? (
            <div className="px-6 pt-6">
              <p className="text-2xl font-bold px-4">Facilities</p>
              <div className="px-4 flex flex-col md:flex-row  justify-between">
                <div className="w-full md:w-1/2 p-4 ">
                  <div className="flex my-4 space-x-2 items-center text-xl">
                    <span>
                      <FaStarOfLife />
                    </span>
                    <span className="pl-4">
                      {artistData?.design} Tattoo Designs.
                    </span>
                  </div>

                  {facilities.includes("Parking") && (
                    <div className="flex items-center my-4 space-x-2 text-xl">
                      <span>
                        <AiFillCar />
                      </span>
                      <span className="pl-4">Self-parking $10/Hour.</span>
                    </div>
                  )}

                  {facilities.includes("Parking") && (
                    <div className="flex my-4 space-x-2 items-center text-xl">
                      <span>
                        <AiFillCar />
                      </span>
                      <span className="pl-4">
                        Parking not owned by the Artist Service Provider,
                        $20/night + tax depending on the size of the vehicle and
                        located at 475 W 41st.
                      </span>
                    </div>
                  )}
                  {facilities.includes("Minibar") && (
                    <div className="flex my-4 space-x-2 items-center text-xl">
                      <span>
                        <BiSolidDrink />
                      </span>
                      <span className="pl-4">No minibar.</span>
                    </div>
                  )}
                </div>

                <div className=" w-full lg:w-1/2 p-4 ">
                  {facilities.includes("Wifi") && (
                    <div className="flex items-center my-4 space-x-2 text-xl">
                      <span>
                        <FaWifi />
                      </span>
                      <span className="pl-4">Complimentary WiFi.</span>
                    </div>
                  )}
                  {facilities.includes("Pets") && (
                    <div className="flex my-4 space-x-2 items-center text-xl">
                      <span>
                        <MdPets />
                      </span>
                      <span className="pl-4">No pets.</span>
                    </div>
                  )}
                  {facilities.includes("Tv") && (
                    <div className="flex my-4 space-x-2 items-center text-xl">
                      <span>
                        <BsLaptopFill />
                      </span>
                      <span className="pl-4">32" flat-screen TV.</span>
                    </div>
                  )}
                  {facilities.includes("Drinks") && (
                    <div className="flex my-4 space-x-2 items-center text-xl">
                      <span>
                        <BiSolidDrink />
                      </span>
                      <span className="pl-4">
                        Drinks served at The Terrace and Club Lounge.
                      </span>
                    </div>
                  )}
                  {facilities.includes("Breakfast") && (
                    <div className="flex my-4 space-x-2 items-center text-xl">
                      <span>
                        <MdFreeBreakfast />
                      </span>
                      <span className="pl-4">
                        Breakfast available at additional cost.
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}
          {/* Map component */}
          <div className="p-6">
            <LocationMap bookingPageMap={true} />
          </div>
          {/* <!-- component --> */}
            <div className="px-6">

            <h1 class="py-5 text-4xl font-bold">Artist Reviews</h1>
          <div className="border border-slate-500 rounded-md p-2 max-h-[450px] overflow-y-scroll">
            <h1 class="py-5 text-2xl font-bold">What our bookers think</h1>
            {artistData?.reviewInfo?.map((item, i) => {
              return (
                <div key={i} className="border-b-2 border-b-slate-400/25" >
                  <div className="flex items-center">
                    <img
                      src={artistData?.userId?.avatar[0] || Profile}
                      className="w-[70px] h-[70px] rounded-full p-2"
                      alt=""
                      />
                    <div>
                      <div className="flex">
                        <p className="mr-1">{item.bookingId.firstName}</p>
                        <p>{item.bookingId.lastName}</p>
                      </div>

                      <div className="text-xs">{item.bookingId.email}</div>
                    </div>
                  </div>
                  <p className="p-2 ">{item.reviewText}</p>
                  <p className="p-2">Rating : {item.rating} out of 5</p>
                </div>
              );
            })}
          </div>
            </div>
          {/* Need to know */}
          <div className="p-6">
            <p className="mb-2 text-xl font-semiboldtext-white font-bold">
              Tattoo Night - Need to Know
            </p>
            <ul className="space-y-4 text-lg text-white list-disc list-outside px-6 pb-6 pt-4">
              <li>
                Rate displayed is for the entire event. Admission is guaranteed
                for 1 tattoo enthusiast. Additional attendees are at the event’s
                discretion and may be subject to additional fees.
              </li>
              <li> Event starts at 18:00. End time is 23:00.</li>
              <li>18+ to book.</li>
              <li>Photo ID and credit card required at check-in.</li>
              <li> Smoking is not allowed during the event.</li>
              <li>
                Event staff will be available until 2:00. If you will be
                arriving after 2:00, please call +31-20-262-2102 for
                instructions.
              </li>
              <li>City tax will be collected at check-in or check-out.</li>
              <li> No entry for individuals under 12 years old.</li>
              <li> We do not verify reviews for this event.</li>
            </ul>
          </div>
        </div>

        <div className="w-full sticky flex md:flex-col justify-between bottom-0 gap-1 md:h-[250px] md:w-1/3 md:justify-center md:items center md:rounded-lg md:m-2 py-2 md:py-0 p-8 bg-slate-900 shadow-2xl">
          <p className="text-2xl hidden md:block font-bold py-3">Your Dates</p>
          <div className=" md:py-3 w-4/6 md:w-full gap-1 flex">
            <div className="rounded-sm bg-white p-2 w-full  flex flex-col justify-start items-start ">
              <p className="text-gray-500 text-sm">When</p>
              <DatePicker
                selectsRange={false}
                selected={dateRange}
                onChange={(date) => {
                  handleDateChange(date);
                  setDateRange(date);
                  handleFunction(date);
                }}
                minDate={new Date()}
                isClearable={false}
                className="text-black text-md w-full placeholder:text-gray-400 outline-none"
                placeholderText="Select a Date"
                excludeDates={arrayUnavailabelDates?.map(
                  (dateString) => new Date(dateString)
                )}
                filterDate={filterDate}
              />
            </div>
            <div className="rounded-sm bg-white p-2 w-full flex flex-col justify-start items-start">
              <p className="text-gray-500 text-sm">Duration</p>
              <select
                id="hours"
                name="hours"
                onChange={handleHourChange}
                disabled={hoursValue === 0}
                className="bg-gray-50 border border-none border-gray-300 outline-none text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full "
              >
                {hoursValue === 0 ? (
                  <option key="noHours" value="noHours">
                    Already Booked
                  </option>
                ) : (
                  Array.from({ length: hoursValue }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {console.log("hoursValue", hoursValue)}
                      {index + 1} hours
                    </option>
                  ))
                )}
              </select>
            </div>
          </div>
          <div
            className={`rounded-sm md:py-3 w-2/6 md:w-full cursor-pointer ${
              artistData?.userId?.stripe_refresh_token ? !navigateValue || !selectedHour
                ? "hover:bg-purple-700/25 bg-purple-700/25"
                : "hover:bg-purple-800 bg-purple-700" : "hover:bg-red-800 bg-red-700"
            } flex justify-center items-center h-auto`}
          >
            <button
              disabled={!navigateValue || !selectedHour || hoursValue === 0}
              onClick={() =>
                artistData?.userId?.stripe_refresh_token? navigate("/booknow", {
                  state: { artistData, navigateValue, selectedHour },
                }) : ''
              }
              // disabled={hoursValue === 0}
              className="text-lg md:text-xl cursor-pointer h-full w-full  font-semibold uppercase "
            >
              {hoursValue === 0
                ? "Choose another Date"
                : artistData?.userId?.stripe_refresh_token ? `Book Now  ($${artistData?.price * selectedHour} )` : "Payment not verified"}
            </button>
          </div>
        </div>
        <div className="">
          <p className="text-lg"></p>
        </div>
      </div>
    </div>
  );
};
