import React from "react";
import { useSelector } from "react-redux";

export const OrderList = () => {
  const { services } = useSelector((state) => state.serviceDetails);
  return (
    <div className="p-4 w-full">
      <div className=" h-auto rounded-xl flex gap-4 md:m-4 p-5 flex-wrap justify-center md:justify-start items-center">
        {services[0]?.orderInfo.length > 0 ? (
          services[0]?.orderInfo.map((item) => {
            return (
              <>
                <div
                  className=" md:w-[48%] mb-1 bg-gray-700 rounded-xl"
                  key={item?._id}
                >
                  <ul className="  divide-y divide-gray-200 border rounded-xl border-gray-500">
                    <li className="p-3 sm:p-4">
                      <div className="flex items-center space-x-4 rtl:space-x-reverse">
                        <div className="bg-white shadow-md rounded-md p-6 w-full">
                          <h2 className="text-xl md:text-3xl font-bold text-purple-500 mb-2">
                            {item?.firstName?.charAt(0).toUpperCase() +
                              item?.firstName?.slice(1)}{" "}
                            {item?.lastName?.charAt(0).toUpperCase() +
                              item?.lastName?.slice(1)}
                          </h2>
                          <p className="text-sm text-gray-600 mb-2">
                            <span className="font-bold">Email:</span>{" "}
                            {item?.email}
                          </p>
                          <p className="text-sm text-gray-600 mb-2">
                            <span className="font-bold">Contact Number:</span>{" "}
                            {item?.phoneNumber}
                          </p>
                          <div className="flex items-center gap-4 mb-2">
                            <p className="text-sm text-gray-600">
                              <span className="font-bold">Paid Hours:</span>{" "}
                              {item?.bookingData?.hours}
                            </p>
                            <p className="text-sm text-gray-600">
                              <span className="font-bold">Total Price:</span> $
                              {item?.bookingData?.totalPrice}
                            </p>
                          </div>
                          <p className="text-sm text-gray-600">
                            <span className="font-bold">Booked Date:</span>{" "}
                            {item?.bookingData?.bookedDate}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            );
          })
        ) : (
          <p>No orders booked yet.</p>
        )}
      </div>
    </div>
  );
};
