import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ApiService from "../../api/ApiService";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import {
  ShowErrorToast,
  ShowSuccessToast,
} from "../../toastMessage/ToastMessage";
import { useNavigate } from "react-router-dom";

export const Login = ({ loginState, setLoginState, setSignUpState }) => {
  const navigate = useNavigate("");
  const [loginBox, setLoginBox] = useState(true);
  const [gotMailBox, setGotMailBox] = useState(false);
  const [linkReqBox, setLinkReqBox] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [showSubmitting, setShowSubmitting] = useState(false);
  const [showLinkRequest, setShowLinkRequest] = useState(false);
  const [logUsingPasswordBox, setLogUsingPasswordBox] = useState(false);

  // const formik = useFormik({
  //   initialValues: {
  //     email: "",
  //   },
  //   validationSchema: Yup.object({
  //     email: Yup.string()
  //       .email("Invalid email address")
  //       .required("Email Required"),
  //   }),
  //   onSubmit: (values) => {
  //     console.log("Form submitted with values:", values);
  //     handleLoginModal();
  //   },
  // });
  // const handleLoginModal = () => {
  //   setLoginState(true);
  //   setShowSubmitting(true);
  //   setShowLinkRequest(true);
  //   setLoginBox(false);
  //   setLinkReqBox(true);
  //   setGotMailBox(false);
  // };

  // const handleLinkRequest = () => {
  //   setShowLinkRequest(false);
  //   setLoginBox(false);
  //   setLinkReqBox(false);
  //   setGotMailBox(true);
  //   setLogUsingPasswordBox(false);
  // };
  // const handleGotMailBox = () => {
  //   setShowLinkRequest(false);
  //   setLoginBox(false);
  //   setLinkReqBox(false);
  //   setGotMailBox(false);
  //   setLogUsingPasswordBox(true);
  // };

  const closeModal = () => {
    setLoginState(false);
    setSignUpState(false);
    setShowLinkRequest(false);
    setLoginBox(false);
    setLinkReqBox(false);
    setGotMailBox(false);
    setLogUsingPasswordBox(false);
  };
  const openSignUpModal = () => {
    setSignUpState(true);
    setLoginState(false);
  };
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (loginState && e.target.id === "authentication-modal") {
        closeModal();
      }
    };

    if (loginState) {
      document.body.addEventListener("click", handleOutsideClick);
    } else {
      document.body.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [loginState]);
  const passFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email Required"),
      password: Yup.string()
        .min(6, "Must be 6 characters")
        .required("Password Required"),
    }),
    onSubmit: async (values) => {
      try {
        setShowSubmitting(true);
        const response = await ApiService.login("login", values);

        if (response.user.role === "artist") {
          navigate("/admin-artist");
          window.location.reload();
        } else if (!response.user.role) {
          navigate("/dashboard");
          window.location.reload();
        }

        localStorage.setItem("userData", JSON.stringify(response));
        closeModal();
        ShowSuccessToast("Login Successfully");
      } catch (error) {
        setShowSubmitting(false);
        ShowErrorToast(error.response.data.message);
      }
      window.location.reload();
    },
  });
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className=" mx-auto ">
      {/* {loginState && loginBox && (
        <div
          id="authentication-modal"
          aria-hidden="true"
          className="absolute h-[100vh] w-full justify-center overflow-x-hidden  flex items-center   z-50"
        >
          <div className="relative w-full max-w-md px-0 md:px-4  md:h-auto">
            <LoginModal
              openSignUpModal={openSignUpModal}
              closeModal={closeModal}
              handleLoginModal={handleLoginModal}
            />
          </div>
        </div>
      )}
      {loginState && linkReqBox && (
        <div
          id="authentication-modal"
          aria-hidden="true"
          className="absolute h-[100vh] w-full justify-center overflow-x-hidden  flex items-center   z-50"
        >
          <div className="relative w-full max-w-md px-0 md:px-4  md:h-auto">
            <LinkReqModal
              closeModal={closeModal}
              handleLinkRequest={handleLinkRequest}
              handleGotMailBox={handleGotMailBox}
              showLinkRequest={showLinkRequest}
            />
          </div>
        </div>
      )}
      {loginState && gotMailBox && (
        <div
          id="authentication-modal"
          aria-hidden="true"
          className="absolute h-[100vh] w-full justify-center overflow-x-hidden  flex items-center   z-50"
        >
          <div className="relative w-full max-w-md px-0 md:px-4  md:h-auto">
            <GotMailBoxModal closeModal={closeModal} />
          </div>
        </div>
      )} */}
      {/* {loginState && logUsingPasswordBox && ( */}

      {/* ---------------------------------- */}
      {/* <div
          id="authentication-modal"
          aria-hidden="true"
          className="absolute h-[100vh] w-full justify-center overflow-x-hidden  flex items-center   z-50"
        >
          <div className="relative w-full max-w-md px-0 md:px-4  md:h-auto"> 
            <LoginUsingPasswordModal
              closeModal={closeModal}
              openSignUpModal={openSignUpModal}
            /> 
         </div>
        </div> 
    )}  
*/}

      <div
        id="authentication-modal"
        aria-hidden="true"
        className="absolute h-[100vh] w-full justify-center overflow-x-hidden  flex items-center   z-50"
      >
        <div className="relative w-full max-w-md px-0 md:px-4  md:h-auto">
          <div className="bg-purple-700 shadow relative">
            <div className="flex justify-end p-0">
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:text-gray-900 rounded-lg text-lg p-1.5 ml-auto inline-flex items-center"
                onClick={closeModal}
              >
                <svg
                  className="w-7 h-7"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <form
              className="space-y-6 px-6 gap-4 lg:px-8 pb-4 sm:pb-6 xl:pb-8"
              onSubmit={passFormik.handleSubmit}
            >
              <h3 className="text-3xl font-medium text-white">Login</h3>

              <div className="relative">
                <input
                  type="text"
                  name="email"
                  id="email"
                  autoComplete="new-email"
                  aria-describedby="email_text"
                  className="block px-2.5 pb-2.5 pt-5 w-full text-lg text-gray-900 bg-gray-50 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=""
                  onChange={passFormik.handleChange}
                  value={passFormik.values.email}
                />
                <label
                  htmlFor="email"
                  className="absolute text-lg text-gray-500   duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-black  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                >
                  {passFormik.touched.email && Boolean(passFormik.errors.email)
                    ? `${passFormik.errors.email}`
                    : "Email"}
                </label>
              </div>

              <div className="relative">
                <input
                  name="password"
                  id="password"
                  autoComplete="new-password"
                  aria-describedby="password_text"
                  className="block px-2.5 pb-2.5 pt-5 w-full text-lg text-gray-900 bg-gray-50   border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=""
                  onChange={passFormik.handleChange}
                  value={passFormik.values.password}
                  type={showPassword ? "password" : "text"}
                />
                <label
                  htmlFor="password"
                  className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-black    peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                >
                  {passFormik.touched.password &&
                  Boolean(passFormik.errors.password)
                    ? `${passFormik.errors.password}`
                    : "Password"}
                </label>
                <div
                  onClick={handleTogglePasswordVisibility}
                  className="absolute cursor-pointer right-4  top-4 text-2xl text-gray-500"
                >
                  {showPassword ? (
                    <MdOutlineVisibilityOff />
                  ) : (
                    <MdOutlineVisibility />
                  )}
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className={`w-full text-white bg-darkPurple hover:bg-purple-950 focus:ring-4 font-medium text-lg px-5 py-2.5 text-center ${
                    showSubmitting ? "opacity-50" : ""
                  }`}
                  disabled={showSubmitting}
                >
                  {showSubmitting ? "Loading..." : "Login"}
                </button>
              </div>
              <div className="text-sm font-mediutext-gray-300 text-center">
                Don't Have an Account?{" "}
                <span
                  className="font-bold underline cursor-pointer"
                  onClick={openSignUpModal}
                >
                  Sign Up
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
