import React, { useEffect, useState } from "react"

import ImageModal from "../artistBooking/ImageModal"
import { MdModeEdit } from "react-icons/md"
import { useSelector } from "react-redux"

const ServiceProfile = ({ setShowSetting }) => {
  const { services } = useSelector((state) => state.serviceDetails)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }
  console.log("servcie :",services)
  // Helper function to render service information
  const renderServiceInfo = (label, value) => (
    <div>
      <label className="block mb-1 text-sm font-normal text-gray-300">{label}</label>
      <p className="text-lg font-medium">{value}</p>
    </div>
  )
  function renderTableInfo(label, data) {
    const dataArray = data.length > 0 ? data[0].split(',') : [];
    return (
      <div>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left border border-gray-500 rtl:text-right text-white">
            <thead className="text-xs text-gray-700 capitalize bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  {label}
                </th>
              </tr>
            </thead>
            <tbody>
              {dataArray.map((item, index) => (
                <tr key={index} className="border-b ">
                  <td className="px-6 py-4 font-medium text-white whitespace-nowrap ">
                    {item.trim()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  function renderObjTableInfo(label, data) {
   
    return (
      <div>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left border border-gray-500 rtl:text-right text-white">
            <thead className="text-xs text-gray-700 capitalize bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  {label}
                </th>
              </tr>
            </thead>
            <tbody>
              {typeof data === 'string'
                ? data
                : data?.map((item, index) => (
                    <tr key={index} className="border-b">
                      <td className="px-6 py-4 font-medium text-white whitespace-nowrap">
                        {item.trim()}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  
  
  return (
    <>
      <div className="md:flex p-4 w-full">
        <ImageModal isModalOpen={isModalOpen} closeModal={closeModal} artistData={services[0]} />
        <div className="md:w-3/5 rounded-xl bg-gray-700 md:m-4 p-5 min-h-[500px]">
          <div className="flex items-center justify-between w-full">
            <p className=" text-3xl py-4 font-bold text-white">Service Details</p>
            <div onClick={() => setShowSetting("setting")}>
              <MdModeEdit className="text-4xl cursor-pointer mt-2 p-1 bg-gray-700 border border-white rounded-3xl" />
            </div>
          </div>
          <div className="">
            <div className="mb-2">
              <label className="block mb-1 text-sm font-normal text-gray-300">
                Title
              </label>
              <p className="text-lg font-bold">{services[0]?.title} </p>
            </div>
            <div className="my-8">
              <label className="block mb-1 text-sm font-medium text-gray-300">
                Bio
              </label>
              <p className="text-lg font-bold">{services[0]?.about} </p>
            </div>
            <div className="grid gap-6  mb-6 md:grid-cols-2 text-xl">
              {renderServiceInfo("Price", `$${services[0]?.price}`)}
              {renderServiceInfo("Rating", services[0]?.ratingPercent ? `${services[0]?.ratingPercent}%` : "no one rated yet")}
              {renderServiceInfo("Hours per Day", services[0]?.hours_perday)}
              <div className=""></div>
              {renderObjTableInfo("Booked Dates", services[0]?.bookDates?.length > 0 ? services[0]?.bookDates.map((item) => item.date ) : "no one booked yet")}
              {renderTableInfo("Unavailable Dates", services[0]?.unAvailableDates?.length > 0 ? services[0]?.unAvailableDates : "no date selected yet")}
              {renderTableInfo("Unavailable Days", services[0]?.unAvailableDays?.length > 0 ? services[0]?.unAvailableDays : "No days unavailable")}
              {renderTableInfo("Facilities", services[0]?.facilities && services[0]?.facilities.length > 0 ? services[0]?.facilities : "No facilities available")}
              {renderTableInfo("Specialities", services[0]?.specialities.length > 0 ? services[0]?.specialities : "No speciality added")}
            </div>
            <div className="mb-2">
              <label className="block mb-1 text-sm font-medium text-gray-300">
                Location
              </label>
              <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                  <thead className="text-xs text-gray-700 capitalize bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Street
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Street Number
                      </th>
                      <th scope="col" className="px-6 py-3">
                        City
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Zip
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className=" border border-gray-500">
                      <td className="px-6 py-4 font-medium text-white whitespace-nowrap ">
                        {services[0]?.userId?.address?.street}
                      </td>
                      <td className="px-6 py-4 font-medium text-white whitespace-nowrap ">
                        {services[0]?.userId?.address?.number}
                      </td>
                      <td className="px-6 py-4 font-medium text-white whitespace-nowrap ">
                        {services[0]?.userId?.address?.city}
                      </td>
                      <td className="px-6 py-4 font-medium text-white whitespace-nowrap ">
                        {services[0]?.userId?.address?.zip}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-2/5 flex flex-col justify-start items-center">
          <div className="w-full rounded-xl bg-gray-700 m-4 p-2  flex flex-col justify-start items-center gap-3 pt-16">
            <div className="grid grid-cols-1 gap-2 -mt-14" onClick={openModal}>
              {services[0]?.portfolioImages.map((image, index) => (
                <div key={index} className="">
                  <img
                    src={image}
                    alt={`${index}`}
                    className="w-[250px] h-[250px] rounded-lg"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceProfile
