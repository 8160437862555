import React, { useState } from "react";
import TattoHero from "../../assets/images/tattoo-hero.avif";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import artistData from "../data/artist_data.json";
import InputSection from "./InputSection";

export const Hero = () => {
  const navigate = useNavigate();
  
  const [inputFilter, setInputFilter] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date()); // Single selected date

  const handleDropdown = (ele) => {
    setSelectedValue(ele);
    setInputValue(ele);
    setShowDropdown(false);
    // Update the selected date
    setSelectedDate(selectedDate);
  };

  // Handle date picker for navigation
  const handleDateChange = (val) => {
    // Set the selected date
    setSelectedDate(val);
  };

  const artists = artistData.artists;
  const placeData = artists.map((element) => element.location);

  const handleInput = (event) => {
    const input = event.target.value;
    setInputValue(input);
    const filterResult = placeData.filter((ele) =>
      ele.toLowerCase().includes(input.toLowerCase())
    );
    setInputFilter(filterResult);
    setShowDropdown(true);
  };

  const backgroundImageStyle = {
    backgroundImage: `url("https://static.wixstatic.com/media/f12c8a6019f146caa1919c0c095c7923.jpg/v1/fill/w_640,h_492,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/f12c8a6019f146caa1919c0c095c7923.jpg")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
console.log("thee selected elee is here " , selectedValue);
  return (
    <div className="text-white flex h-auto md:h-[90vh]  items-center flex-col justify-center" style={backgroundImageStyle}>
      <p className="w-full mt-[120px]  sm:mt-30 md:w-3/5  text-4xl md:text-4xl lg:text-6xl xl:text-6xl 2xl:text-7xl font-bold text-center">
        Incredible last-minute tattoo deals
      </p>
      <p className="w-full md:w-3/5  px-6 text-sm md:text-lg lg:text-xl xl:text-lg my-4 text-center font-bold">
        SAVE TIME AND MONEY BY BOOKING TONIGHT AND BEYOND.
      </p>
      <div className="md:flex items-center w-full justify-center">
        <InputSection
          inputValue={inputValue}
          setShowDropdown={setShowDropdown}
          handleInput={handleInput}
          inputFilter={inputFilter}
          showDropdown={showDropdown}
          handleDropdown={handleDropdown}
          selectedDate={selectedDate} // Pass the selected date
          handleDateChange={handleDateChange}
          placeData={placeData}
        />
        <div className={`rounded-sm  lg:w-[80px] mt-3 cursor-pointer mx-4 md:mx-0 flex justify-center items-center h-16 `}>
          <button
            disabled={inputValue === ""}
            onClick={() => {
              navigate("/artistcard", { state: { selectedDate ,inputValue } }); // Pass the selected date
            }}
            className={`text-xl ${inputValue === "" ? "hover:bg-purple-700/25 bg-purple-700/25" : "hover-bg-purple-800 bg-purple-700"} cursor-pointer h-full w-full font-semibold uppercase`}
          >
            GO
          </button>
        </div>
      </div>
      <div className="w-full lg:w-2/4 md:w-2/4 xl:w-2/4 text-center">
        <a href="#" rel="noopener noreferrer">
          <p className="text-base sm:text-lg md:text-xl lg:text-lg xl:text-lg 2xl:text-4xl my-2 font-bold">
            We encourage all travelers to read this update on how COVID-19 may
            affect travel and our cancellation policy. Learn more.
          </p>
        </a>
      </div>
      <div className="pl-3 w-full  flex items-center">
        <a href="#" rel="noopener noreferrer" className="flex items-center">
          <p className="text-xs md:text-lg lg:text-lg xl:text-lg 2xl:text-4xl my-2 font-bold">
            {/* Santa Monica Proper Hotel | Los Angeles, CA */}
          </p>
          {/* <IoIosArrowForward className="ml-2" /> */}
        </a>
      </div>
    </div>
  );
};