import React from "react";

const RoomSummary = ({ onNext, valueOfBookNow }) => {
  return (
    <>
      <div>
        <h1 className="text-3xl font-bold md:text-center ">Tattoo Night Summary</h1>
      </div>
      {/* <div className="flex justify-between space-x-8 max-sm:space-x-12">
        <h1 className="text-lg">Artist Location </h1>
        <h1 className="font-bold text-lg">{valueOfBookNow?.artistData?.desc}</h1>
      </div> */}
      {/* <div className="flex justify-between space-x-8 max-sm:space-x-12">
        <h4 className="text-lg">Tattoo Quantity </h4>
        <h1 className="font-bold text-lg">1</h1>
      </div> */}
      <div className="flex justify-between space-x-8 max-sm:space-x-12">
        <h4 className="text-lg">Speciality</h4>
        <button className="font-bold text-lg">
          {" "}
          {valueOfBookNow?.artistData?.specialities.join(", ") || "NAN"}
        </button>
      </div>
      <div className="flex justify-between space-x-8 max-sm:space-x-12 ">
        <h1 className=" text-lg">Date</h1>
        <h1 className="font-bold text-lg">
          {valueOfBookNow?.navigateValue instanceof Date
            ? valueOfBookNow?.navigateValue.toLocaleDateString()
            : ""}
        </h1>
      </div>
      <h1 className="border-b-2 border-gray-700"></h1>
      <div className="flex justify-between space-x-8 max-sm:space-x-16">
        <h1 className="text-lg">Price Per Hour</h1>
        <h1 className="font-bold text-lg">
          ${valueOfBookNow?.artistData?.price}
        </h1>
      </div>
      <div className="flex justify-between space-x-8 max-sm:space-x-16">
        <h1 className="text-lg">Total Hours</h1>
        <h1 className="font-bold text-lg">{valueOfBookNow?.selectedHour}</h1>
      </div>
      <div className="flex justify-between space-x-8 max-sm:space-x-16">
        <h1 className=" text-lg">Total Price</h1>
        <h1 className="font-bold text-lg">
          ${valueOfBookNow?.artistData?.price * valueOfBookNow?.selectedHour}{" "}
        </h1>
      </div>
      {/* <div className="flex justify-between space-x-8 max-sm:space-x-16">
        <button className="text-lg">Promo Code</button>
      </div> */}
      <div className="flex justify-between space-x-8 max-sm:space-x-16">
        <h1 className="text-lg   ">Taxes and Fees</h1>
        <h1 className="font-bold text-lg">$10</h1>
      </div>
      {/* <h1 className="border-b-2 border-gray-700"></h1> */}
      {/* <div className="flex justify-between space-x-8 max-sm:space-x-16">
        <button className=" text-lg  ">Due at Property &#63;</button>
        <h1 className="font-bold text-lg">$100</h1>
      </div> */}
      <h1 className="border-b border-gray-700"></h1>
      <div className="font-bold text-lg">
        <button
          className="font-bold rounded bg-purple-500 hover:bg-purple-700 text-white py-2 w-full"
          onClick={onNext}
        >
          CONTINUE TO GUEST INFO
        </button>
      </div>
    </>
  );
};
export default RoomSummary;
