import React, { useEffect, useState } from "react";
import logo from "../../assets/images/profile.jpeg";
import GuestInfo from "./BookingSteps/GuestInfo";
import RoomSummary from "./BookingSteps/RoomSummary";
import { CardPayment } from "./BookingSteps/CardPayment";
import { useLocation, useNavigate } from "react-router-dom";
import StripeContainer from "./BookingSteps/StripeContainer";
import { ShowErrorToast, ShowSuccessToast } from "../toastMessage/ToastMessage";
import ApiService from "../api/ApiService";
import ReviewForm from "./BookingSteps/ReviewForm";
import ThanksForBooking from "./BookingSteps/ThanksForBooking";
import { useSelector } from "react-redux";

const BookNow = () => {
  const location = useLocation();
  const [formatDate, setFormatDate] = useState();
  const [dateState, setDateState] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [bookingDataRes, setBookingDataRes] = useState(null);
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const {
    services,
    singleService,
    serviceLoading,
    serviceError,
    handleFetchServices,
  } = useSelector((state) => state.serviceDetails);
  const [apikey, setapikey] = useState(
    "pk_test_51M5HJ4JD58aShrSJsU2hxY0clkbIxcx4qjzOqoSt5OQBiwydahoC9JarzofOSDyokOWEfs5jXv2B5s4YcEFoRFUh00MuUc3Ku0"
  );
  const valueOfBookNow = location.state;
  const serviceData = valueOfBookNow?.artistData;
  const navigateValue = valueOfBookNow?.navigateValue;
  const selectedHour = valueOfBookNow?.selectedHour;
  const totalHour = serviceData?.hours_perday;
  const availableHours = parseInt(totalHour) - parseInt(selectedHour);
  const totalPrice = serviceData?.price * selectedHour;

  // useEffect(() => {
  useEffect(() => {
    if (navigateValue) {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      const formattedDate = navigateValue.toLocaleDateString("en-GB", options);
      setFormatDate(formattedDate);
    }
  }, [navigateValue]);

  const [guestInfo, setGuestInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    bookingData: {},
  });
  
  const bookingSubmit=async()=>{
    setCheckoutLoading(true)
    await ApiService.createBookingData("booking", bookedData)
    .then((res) => {
      ShowSuccessToast("Order has placed successfully!");
      nextStep();
      setBookingDataRes(res);
      setCheckoutLoading(false)
      console.log("the response here : ", res);
    })
    .catch((error) => {
      console.log(error);
      setCheckoutLoading(false)
    });
  }

  const makePayment =() => {
    // const stripeAcessToken = services[0]?.userId?.stripe_acess_token;
    try {
   
    const paymentHandler = window.StripeCheckout.configure({
      key: apikey, // Replace with your actual Stripe key
      locale: "auto",
      token: function (stripeToken) {
        console.log("stripe token1 console:", stripeToken);
        paymentStripe(stripeToken);
      },
    });

    const paymentStripe = async (stripeToken) => {
      console.log("stripeToken: ", stripeToken.id);
      const amountInDollars = totalPrice;
      const amountInCents = Math.round(amountInDollars * 100);

      // Calculate 20% of the total amount as the fee
      const feePercentage = 0.25;
      const feeAmountInDollars = amountInDollars * feePercentage;
      const feeAmountInCents = Math.round(feeAmountInDollars * 100);
      
      const data = {
        amount: amountInCents,
        currency: "usd",
        source: stripeToken.id,
        description: "TattoNight order charge",
        application_fee_amount: feeAmountInCents,
        transfer_data: {
          // destination: services[0]?.userId?.stripe_account_id, // Replace with your actual Stripe account ID
          destination: valueOfBookNow.artistData?.userId?.stripe_account_id, // Replace with your actual Stripe account ID
        },
      };
      // Assuming checkout and userData are defined elsewhere
      
   let resPayment=  await   ApiService.makePayments(data, valueOfBookNow.artistData?.userId?._id, "6544869bb77fda7de97d4040")
   if(resPayment){
     bookingSubmit()
     
     }
  };
  
  paymentHandler.open({
    name: "TattoNight",
    amount: totalPrice * 100,
  });
} catch (error) {
      console.log("error :",error)
}
  };
  useEffect(() => {
    

    const invokeStripe = () => {
      if (!window.document.getElementById("stripe-script")) {
        const script = window.document.createElement("script");
        script.id = "stripe-script";
        script.type = "text/javascript";
        script.src = "https://checkout.stripe.com/checkout.js";
        script.onload = () => {
          window.paymentHandler = window.StripeCheckout.configure({
            key: apikey, // Replace with your actual Stripe key
            locale: "auto",
            token: function (stripeToken) {
              console.log(stripeToken);
            },
          });
        };
        window.document.body.appendChild(script);
      }
    };

    // Placeholder for date formatting logic
    const formattedDate = new Date().toLocaleDateString(); // Update this line with your actual date formatting logic

    invokeStripe();

    setGuestInfo((prevGuestInfo) => ({
      ...prevGuestInfo,
      bookingData: {
        hours: selectedHour,
        bookedDate: formattedDate,
        totalPrice: totalPrice,
      },
    }));
  }, [formatDate, selectedHour, totalPrice]);
  useEffect(() => {
    setGuestInfo((prevGuestInfo) => ({
      ...prevGuestInfo,
      bookingData: {
        hours: selectedHour,
        bookedDate: formatDate,
        totalPrice: totalPrice,
      },
    }));
  }, [formatDate, selectedHour, totalPrice]);

  const artistInfo = {
    dateState,
    availableHours: totalHour - selectedHour,
    servedHours: selectedHour,
  };
  const allbookdatesArray = valueOfBookNow?.artistData.bookDates ?? [];

  const filterDates = (allbookdatesArray, formatedData) => {
    let filteredDates = allbookdatesArray?.filter((item) => {
      // Check if the date matches the specified format
      if (item.date == formatedData) {
        // If it matches, don't include it in the filtered array for now
        return false;
      }
      // Otherwise, include it in the filtered array
      return true;
    });
    // return  console.log("filteredDates :",filteredDates,"allbookdatesArray:",allbookdatesArray)
    // Find the index where the item with the specified format should be inserted
    const insertIndex = filteredDates?.findIndex(
      (item) => item.date > formatedData
    );
    // If insertIndex is -1, it means the item should be added at the end
    const findAvailableHours = allbookdatesArray.find(
      (elem) => elem.date == formatedData
    );
    if (insertIndex === -1) {
      filteredDates.push(
        {
          date: formatedData,
          value: findAvailableHours
            ? parseInt(findAvailableHours?.value) - parseInt(selectedHour)
            : parseInt(availableHours),
        } /* other properties if any */
      );
    } else {
      // Insert the item at the specified index
      filteredDates?.splice(insertIndex, 0, {
        date: formatedData,
        value: findAvailableHours
          ? parseInt(findAvailableHours?.value) - parseInt(selectedHour)
          : parseInt(availableHours),
      });
    }
    console.log("the filter dates are here : ", filteredDates);
    return filteredDates;
  };

  // Example usage

  const filteredDates =
    formatDate && filterDates(allbookdatesArray, formatDate);
  console.log(
    "the filtered Datesss is here to show the values : ",
    filteredDates
  );
  const navigate = useNavigate();
  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    } else {
      setCurrentStep(1);
      navigate("/artistcard");
    }
  };
  const bookedData = {
    guestInfo,
    serviceId: serviceData?._id,
    bookingDates: filteredDates,
  };
  useEffect(() => {
    // Set the formatted date when the component mounts
    const dateObject = new Date(navigateValue);
    setDateState(dateObject.toLocaleDateString("en-GB"));
  }, [navigateValue]); // Add navigateValue as a dependency to re-run the effect when it changes

  const handleBookingClick = async () => {
    makePayment();
    // console.log("responsePayment :",responsePayment)
    // return console.log("booking data @@@",bookedData,"services < ", valueOfBookNow.artistData?.userId?.stripe_account_id)
    
   
  };

  const checkout = async () => {
    try {
      const res = await fetch("http://localhost:8000/checkout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          items: [
            {
              id: 1,
              quantity: selectedHour,
              price: serviceData?.price,
              name: serviceData?.specialities[0],
            },
          ],
        }),
      });
      const data = await res.json();
      if (res.status === 200) {
        window.location = data.url;
        ShowSuccessToast("Payment was sucessfull");
      } else {
        ShowErrorToast("Payment was not sucessfull");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="flex flex-col-reverse lg:flex-row 
    xl:flex-row bg-gray-900 max-lg:space-y-6 py-20 mx-4"
    >
      <div className=" w-1/2 max-lg:w-full ">
        <div
          className="flex flex-col space-y-6  
        px-4 md:px-14"
        >
          <div>
            <button
              className="font-bold rounded text-lg underline-button 
            hover:underline hidden lg:block"
              onClick={prevStep}
            >
              &#10094; Back
            </button>
          </div>

          {currentStep === 1 && (
            <RoomSummary onNext={nextStep} valueOfBookNow={valueOfBookNow} />
          )}
          {/* {currentStep === 2 && <GuestInfo onNext={nextStep} />} */}
          {currentStep === 2 && (
            <GuestInfo
              // handleBooking={handleBooking}
              guestInfo={guestInfo}
              setGuestInfo={setGuestInfo}
              onNext={nextStep}
            />
          )}
          {currentStep === 4 && (
            <ReviewForm
              bookingId={bookingDataRes?._id}
              serviceId={serviceData?._id}
            />
          )}
          {currentStep === 5 && <StripeContainer onNext={nextStep} />}
          {currentStep === 3 && (
            <ThanksForBooking
              handleBooking={handleBookingClick}
              guestInfo={guestInfo}
              setGuestInfo={setGuestInfo}
              onNext={nextStep}
              bookedData={bookedData}
              selectedHour={selectedHour}
              formatDate={formatDate}
              valueOfBookNow={valueOfBookNow}
              checkoutLoading={checkoutLoading}
            />
          )}
        </div>
      </div>
      <h1 className="border-l-2 border-gray-700 pl-20 ml-20"></h1>
      <div
        className="w-1/2 space-y-5 pt-14 max-lg:w-full max-lg:pt-0 
      max-lg:pl-40 max-md:pl-16 max-sm:pl-8"
      >
        <button
          className="font-bold rounded text-lg underline-button 
          hover:underline hidden max-lg:block "
          onClick={prevStep}
        >
          &#10094; Back
        </button>
        <img
          src={serviceData?.portfolioImages[0]}
          className="w-9/12  max-lg:pt-8 "
        />
        <p className=" bg-yellow-700 w-fit p-1">VIP</p>
        <h1 className="font-bold text-xl">
          {/* {valueOfBookNow?.serviceData?.location} */}
        </h1>
        <h1 className="font-bold text-xl">
          &#128077; {serviceData?.ratingPercent}% • 17 Ratings
        </h1>
        {/* <h1 className='font-bold text-xl'>
          &#128197; Sat, Oct 28 – Wed, Nov 8
        </h1> */}
      </div>
    </div>
  );
};

export default BookNow;
