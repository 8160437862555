import React from "react";
import { ImQuotesLeft } from "react-icons/im";
import icondata from "../../assets/images/huawei.png";

export const ReviewsSection = () => {
  const arr = [
    "TattooNow is a game-changer for the ink enthusiasts out there! I had a spontaneous urge for a new tattoo and found amazing deals on this app. ",
    "InkExpress is my go-to app for spontaneous tattoos. I recently used it to find a skilled artist available on short notice, and the discounts offered were phenomenal.",
    "LastMinInk is the perfect solution for my instant ink cravings. Whether you're a spontaneous soul or just looking for great deals on quality tattoos.",
  ];

  return (
    <>
      <div className="bg-gradient-custom px-5 py-10 md:p-20">
        <h2 className="font-bold md:text-5xl text-3xl drop-shadow-lg text-black text-center">
          In the News
        </h2>
        <div className="flex flex-wrap justify-center w-full my-10">
          {arr.map((review, index) => (
            <div key={index} className="flex md:w-[300px] lg:w-[340px]">
              <div className="p-2">
                <ImQuotesLeft className="text-black text-2xl" />
              </div>
              <div className="p-2">
                <p className="md:text-2xl text-xl text-black">{review}</p>
                <img
                  src={icondata}
                  className="w-28 block mx-auto mt-2"
                  alt=""
                />
              </div>
            </div>
          ))}
        </div>
        <div className="border-b-2 border-b-black/25 border-t-2 border-t-black/25 px-4 md:py-10 py-4 flex-wrap flex justify-center items-center">
          <p className="font-bold md:text-3xl text-xl text-black p-2 md:ml-4">
            Forbes
          </p>
          <p className="font-bold md:text-3xl text-xl text-black p-2 md:ml-4">
            Wired
          </p>
          <p className="font-bold md:text-3xl text-xl text-black p-2 md:ml-4">
            WSJ
          </p>
          <p className="font-bold md:text-3xl text-xl text-black relative p-2 md:ml-4">
            G <span className="absolute left-6">Q</span>
          </p>
        </div>
      </div>
    </>
  );
};
