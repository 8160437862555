import React, { useState } from "react";
import Modal from "../dashboard/Modal";
import { CarouselComponent } from "../carousel/Carousel";

const ImageModal = ({ isModalOpen, closeModal, artistData }) => {
  console.log(artistData)
  return (
    <Modal isOpen={isModalOpen} closeModal={closeModal}>
      <div className=" md:w-[700px] w-[400px]  ">
        <CarouselComponent modal="modal" artistData={artistData} />
      </div>
      <button
        onClick={closeModal}
        className="text-white text-sm  rounded-md mt-2 ml-auto block px-2 py-2 bg-purple-500 hover:bg-purple-700 "
      >
        Close
      </button>
    </Modal>
  );
};
export default ImageModal;
