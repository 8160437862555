import React, { useState } from "react";
import ReactStarsRating from "react-awesome-stars-rating";
import ApiService from "../../api/ApiService";
import { useNavigate } from "react-router-dom";

const ReviewForm = ({bookingId, serviceId}) => {
  const navigate = useNavigate()
  console.log(bookingId,"service id", serviceId)
  const [formData, setFormData] = useState({
    rating: 0,
    reviewText: "",
    bookingId: bookingId,
  });

  const ratingChanged = (newRating) => {
    setFormData({ ...formData, rating: newRating });
  
  };
  console.log("review form data is here to show",formData , "service id are show here ", serviceId)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  const handleSubmit =  (e) => {
    e.preventDefault()
  const reviews = {
    review: {
      reviewText: formData.reviewText,
      rating: formData.rating,
      bookingId: bookingId, // Make sure bookingId is set correctly here
    },
    serviceId: serviceId, // You can replace this with a valid Service ID
  };
    console.log("the response of post api are shown here ",reviews);

    ApiService.reviewArtist("review", reviews).then((res)=>{
      console.log("the response of post api are shown here ",res);
      if (res) {
          navigate("/artistcard")
      }
    })
    // const reviews = {
    //   review: {
    //     reviewText: "hi",
    //     rating: 8,
    //     bookingId: bookingId, // You can replace this with a valid Booking ID
    //   },
    //   serviceId: serviceId, // You can replace this with a valid Service ID
    // }
    // e.preventDefault();
    // try {
    //   await ApiService.reviewArtist('review', reviews);
    // } catch (error) {
    //   console.error("An error occurred during form submission:", error);
    // } finally {
    //   setFormData({
    //     rating: 1,
    //     reviewText: "",
    //   });
    // }

    // try {
    //   await ApiService.reviewArtist('review', reviews);
    // } catch (error) {
    //   console.error("An error occurred during form submission:", error);
    // }
  }; 

  return (
    <div className="w-full mx-auto mt-6 py-2 border rounded-md max-lg:w-10/12 px-4">
      <h2 className="text-2xl font-semibold mb-4 ">Do you like the service? Please rate the artist</h2>
      <h1 className="my-4"> We value your feedback and continuously strive to enhance our services to ensure an unforgettable tattoo experience for each client</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="rating" className="block text-sm font-medium">
            Ratings
          </label>
          <div className="mt-2">
            <ReactStarsRating
              className='flex'
              count={5}
              onChange={ratingChanged}
              size={32}
              isHalf={true}
              activeColor="#ffd700"
              Edit={true} // Enable edit mode to allow changing color
              value={formData.rating} // Highlight the selected rating
              color="#ffd700" // Set the selected rating color

            />
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="reviewText" className="block text-sm font-medium">
            Your Review
          </label>
          <textarea
            id="reviewText"
            name="reviewText"
            value={formData.reviewText}
            onChange={handleInputChange}
            className="mt-2 p-2 w-full border text-black border-gray-300 rounded-md"
            rows="4"
            required
          ></textarea>
        </div>

        <button
          type="submit"
          className="w-full bg-purple-500 hover:bg-purple-700 text-white py-2 rounded-md transition duration-300
         max-lg:w-11/12 max-lg:ml-5 max-[630px]:w-full"
        >
          Submit Review
        </button>
      </form>
    </div>
  );
};

export default ReviewForm;
