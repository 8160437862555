import React from "react";

const LocationMap = ({ bookingPageMap }) => {
  return (
    <>
      {/* <div className="lg:w-1/3 hidden md:block bg-neutral-500 rounded-md overflow-hidden h-screen sticky top-4 my-4 mx-2"> */}
      <div className={`${bookingPageMap ? 'w-full' : 'lg:w-1/3'
        } hidden md:block bg-neutral-500 rounded-md overflow-hidden h-screen sticky top-4  my-4 ${bookingPageMap ? "mx-0" : "mx-2"}`}>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26423.140816145034!2d72.45143000790358!3d34.123499107473116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38deef78667ee5b3%3A0x878c460ee4a81659!2sSwabi%20Khas%20Gharibabad%20Maniri%2C%20Swabi%2C%20Khyber%20Pakhtunkhwa%2C%20Pakistan!5e0!3m2!1sen!2s!4v1698316525846!5m2!1sen!2s"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Responsive "
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default LocationMap;

