import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { API_BASE_URL } from "../components/api/ApiService";
// import { API_BASE_URL } from "../config/config";

//   export const createUser = createAsyncThunk("createUser", async (data) => {
//     const res = await fetch("localhost:8888/api/v1/signup", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(data),
//     });
//     try {
//       const result = await res.json();
//       return result;
//     } catch (err) {
//       console.log(err);
//       return isRejectedWithValue(err);
//     }
//   });

//   // Update data
//   export const updateUser = createAsyncThunk(
//     "updateUser",
//     async (data, { isRejectedWithValue }) => {
//       const { _id } = data;
//       const res = await fetch(
//         `localhost:8888/api/v1/${_id}`,
//         {
//           method: "PUT",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(data),
//         }
//       );
//       try {
//         const result = await res.json();
//         console.log("=============Updated data in API===========", result);
//         return result;
//       } catch (err) {
//         console.log("-----------Error--------:", err);
//         return isRejectedWithValue(err);
//       }
//     }
//   );

// Show user

export const showUser = createAsyncThunk(
  "showUser",
  async (id) => {
    try {
      const res = await fetch(`${API_BASE_URL}/user/${id}`);
      const result = await res.json();
      return result;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
);
export const showUsers = createAsyncThunk(
  "showUsers",
  async () => {
    const res = await fetch(`${API_BASE_URL}/users`);
    try {
      const result = await res.json();
      return result;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
);
export const userDetails = createSlice({
  name: "userDetails",
  initialState: {
    users: [],
    currentUser: null,
    loading: false,
    error: null,
    handleFetch: false,
  },
  reducers: {
    setHandleFetch: (state, action) => {
      state.handleFetch = action.payload;
      console.log(action)
    },
  },

  extraReducers: (builder) => {
    //current user
    builder
      .addCase(showUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(showUser.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
      })
      .addCase(showUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    //all Users 
    builder
      .addCase(showUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(showUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(showUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default userDetails.reducer;
export const { searchUser, setHandleFetch } = userDetails.actions;
