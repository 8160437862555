import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import profile from "../../assets/images/profile.png";
import { MdModeEdit } from "react-icons/md";
import * as Yup from "yup"
import ApiService from "../api/ApiService";
import { ShowErrorToast, ShowSuccessToast } from "../toastMessage/ToastMessage";
import { setHandleFetch } from "../../features/serviceDetailsSlice";

const DashboardSetting = ({showSetting, setShowSetting}) => {
  const dispatch = useDispatch()
  const [id, setId] = useState()
  const [selectedImage, setSelectedImage] = useState()
  const { currentUser } = useSelector((state) => state.app);
  console.log("current user are shown here :",currentUser)
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      // handleFormik(currentUser);
      setId(parsedUserData?.user?.id);
    }
  }, [currentUser]);
  const formik = useFormik({
    initialValues: {
      firstName: currentUser?.firstName ? currentUser?.firstName : "",
      lastName: currentUser?.lastName || "",
      gender: "",
      phoneNumber: currentUser?.number || "",
      email: currentUser?.email || "",
      avatar: "",
      availableHours:"",
      address: {
        street: currentUser?.address?.street || "",
        number: currentUser?.address?.number || "",
        city: currentUser?.address?.city || "",
        zip: currentUser?.address?.zip || "",
      },
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      gender: Yup.string().required("Gender is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      address: Yup.object().shape({
        street: Yup.string().required("Street is required"),
        number: Yup.string().required("Number is required"),
        city: Yup.string().required("City is required"),
        zip: Yup.string().required("Zip code is required"),
      }),
    }),
    onSubmit: (values) => {
      console.log("onsubmit called ", values);
      const formData = new FormData();

      // Append simple fields
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("gender", values.gender);
      formData.append("phoneNumber", values.phoneNumber);
      formData.append("email", values.email);
      if (values.avatar) {
        formData.append("avatar", values.avatar[0]);
      }
      // Append address fields
      formData.append("address[street]", values?.address?.street);
      formData.append("address[number]", values?.address?.number);
      formData.append("address[city]", values?.address?.city);
      formData.append("address[zip]", values?.address?.zip);
      // const id = profileData?.user.id;
      const userid = `User/${id}`;
      ApiService.updateData(userid, formData)
        .then((data) => {
          ShowSuccessToast(data.message);
          setShowSetting("dashboard")
          dispatch(setHandleFetch(data));
        })
        .catch((err) => ShowErrorToast(err));
      // ShowSuccessToast("Form Submitted");
    },
  });
  console.log("formik errorsss : ",formik.errors);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check if the selected file is an image (you can add more validation)
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setSelectedImage(reader.result);
        };
        // Set the selected image in Formik state
        formik.setFieldValue("avatar", [file]);
      } else {
        alert("Please select a valid image file.");
      }
    }
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit} className="w-full">
        <div className="md:flex p-4 w-full">
          <div className=" md:w-3/5 rounded-xl bg-gray-700  md:m-4 p-5">
            <p className="text-2xl py-4 font-bold">Profile information</p>
            <div className="">
              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block mb-2 text-sm font-medium text-gray-300"
                  >
                    {formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                      ? formik.errors.firstName
                      : "First Name"}
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="John"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block mb-2 text-sm font-medium text-gray-300"
                  >
                    {formik.touched.lastName && Boolean(formik.errors.lastName)
                      ? formik.errors.lastName
                      : "Last Name"}
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Doe"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="gender"
                    className="block mb-2 text-sm font-medium text-gray-300"
                  >
                    {/* {formik.touched.gender && Boolean(formik.errors.gender)
                      ? formik.errors.gender
                      : "Select Gender"} */}
                        {formik.touched.gender &&
                      Boolean(formik.errors.gender)
                      ? formik.errors.gender
                      : "Select Gender"}
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                  >
                    <option value="" label="Gender" />
                    <option value="male" label="Male" />
                    <option value="female" label="Female" />
                    <option value="others" label="Others" />
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="phoneNumber"
                    className="block mb-2 text-sm font-medium text-gray-300"
                  >
                    {formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                      ? formik.errors.phoneNumber
                      : "Phone Number"}
                  </label>
                  <input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="123-45-678"
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
                <p
                    className="block mb-2 text-2xl font-medium text-gray-300 py-3"
                
                >Address</p>
              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div className="md:w-[100%]">
                  <label
                    htmlFor="address.street"
                    className="block mb-2 text-sm font-medium text-gray-300"
                  >
                    {formik.touched.address?.street &&
                      Boolean(formik.errors.address?.street)
                      ? formik.errors.address?.street
                      : "Street"}
                  </label>
                  <input
                    type="text"
                    id="address.street"
                    name="address.street"
                    className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="123 Main Street"
                    value={formik.values?.address?.street}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="md:w-[100%]">
                  <label
                    htmlFor="address.number"
                    className="block mb-2 text-sm font-medium text-gray-300"
                  >
                    {formik.touched.address?.number &&
                      Boolean(formik.errors.address?.number)
                      ? formik.errors.address?.number
                      : "Street No"}
                  </label>
                  <input
                    type="number"
                    id="address.number"
                    name="address.number"
                    className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Apt 4B"
                    value={formik.values.address?.number}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div className="md:w-[100%]">
                  <label
                    htmlFor="address.city"
                    className="block mb-2 text-sm font-medium text-gray-300"
                  >
                    {formik.touched.address?.city &&
                      Boolean(formik.errors.address?.city)
                      ? formik.errors.address?.city
                      : "City"}
                  </label>
                  <input
                    type="text"
                    id="address.city"
                    name="address.city"
                    className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Los Angeles"
                    value={formik.values.address?.city}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="md:w-[100%]">
                  <label
                    htmlFor="address.zip"
                    className="block mb-2 text-sm font-medium text-gray-300"
                  >
                    {formik.touched.address?.zip &&
                      Boolean(formik.errors.address?.zip)
                      ? formik.errors.address?.zip
                      : "Zip"}
                  </label>
                  <input
                    type="number"
                    id="address.zip"
                    name="address.zip"
                    className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="39000"
                    value={formik.values.address?.zip}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-300 "
                >
                  {formik.touched.email && Boolean(formik.errors.email)
                    ? formik.errors.email
                    : "Email Address"}
                </label>
                <input
                  type="email"
                  id="address.zip"
                  name="email"
                  className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="example@gmail.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </div>
              <button
                type="submit"
                className="text-white bg-purple-800 hover:bg-purple-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
              >
                Save All
              </button>
            </div>
          </div>
          <div className="md:w-2/5  flex flex-col justify-start items-center">
            <div className="w-full rounded-xl bg-gray-700  m-4 p-2 flex flex-col justify-start items-center gap-3 pt-16">
              <label className="cursor-pointer relative flex flex-col justify-start items-center ">
                {formik.touched.avatar && Boolean(formik.errors.avatar) && (
                  <p className="text-lg text-red-600">{formik.errors.avatar}</p>
                )}
                <div className="rounded-full h-[150px] w-[150px] border-white overflow-hidden">
                  <img
                    src={selectedImage || currentUser?.avatar || profile}
                    alt="Rounded avatar"
                    className="rounded-full object-cover w-full h-full"
                  />
                </div>
                <input
                  type="file"
                  id="imageInput"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                <div
                  htmlFor="imageInput"
                  className="text-4xl cursor-pointer text-white mt-2 p-1 bg-gray-700 absolute right-1 border border-white rounded-3xl  bottom-0"
                >
                  <MdModeEdit />
                </div>
              </label>

              <p className="text-2xl text-gray-300">
                {currentUser?.firstName} {currentUser?.lastName}
              </p>
              <p className="text-xl text-gray-300">{currentUser?.email}</p>
              {/* <p className="text-lg text-gray-300">
                {currentUser?.address?.street} {currentUser?.address?.city}{" "}
                {currentUser?.address?.zip}
              </p> */}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default DashboardSetting;
