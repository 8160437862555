import React from "react";

const FooterBar = () => {
  return (
    <>
      <div className="p-4 bg-white md:flex justify-between items-center">
        <div className="md:flex items-center">
          <h3 className=" text-gray-500">Logo Tatto</h3>
          <p className="ml-6 text-sm cursor-pointer relative before:contents[] before:absolute before:w-2 before:h-2 before:bg-gray-400 before:rounded-full before:-left-4 before:top-[5px] text-gray-500  hover:text-gray-600">
            Terms of services
          </p>
          <p className="ml-6 text-sm cursor-pointer relative before:contents[] before:absolute before:w-2 before:h-2 before:bg-gray-400 before:rounded-full before:-left-4 before:top-[5px] text-gray-500  hover:text-gray-600">
            Privacy Policies
          </p>
          <p className="ml-6 text-sm cursor-pointer relative before:contents[] before:absolute before:w-2 before:h-2 before:bg-gray-400 before:rounded-full before:-left-4 before:top-[5px] text-gray-500  hover:text-gray-600">
            Cookies Preference
          </p>
        </div>
        <div className="flexitems-center">
          <p className=" text-sm  text-center text-gray-500 tracking-widest">
            @TattoNight.All
          </p>
        </div>
      </div>
    </>
  );
};

export default FooterBar;
