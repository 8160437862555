import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ApiService from "../../api/ApiService";
import { ShowErrorToast, ShowSuccessToast } from "../../toastMessage/ToastMessage";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md"
import { BsLink45Deg } from "react-icons/bs";

export const SignUpModal = ({ signUpState, setSignUpState, setLoginState }) => {


  const [showPassword, setShowPassword] = useState(true);
  const [showSubmitting, setShowSubmitting] = useState(false);

  const handleClick = () => {
    // setShowSubmitting(true)
    console.log('cicked')
  }
  const [identityImageName, setIdentityImageName] = useState("");
  const [businessLicenseImageName, setBusinessLicenseImageName] = useState("");

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      number: "",
      role: "artist",
      identityImage: "",
      businessLicenseImage: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name Required"),
      lastName: Yup.string().required("Last Name Required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email Required"),
      password: Yup.string()
        .min(6, "Must be 6 characters")
        .required("Password Required"),
      number: Yup.string().required("Phone Number Required"),
      identityImage: Yup.mixed().required("Required"),
      businessLicenseImage: Yup.mixed().required("Required")
    }),
    onSubmit: async (values) => {
      try {
        setShowSubmitting(true);
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });
        await ApiService.signup('signup', formData);
        ShowSuccessToast("User Added");
        setSignUpState(false);
        setLoginState(true);
       
      } catch (error) {
        setShowSubmitting(false);
        if (error.response) {
          ShowErrorToast(`Server Error: ${error.response.data.message}`);
        }else {
          ShowErrorToast("An unexpected error occurred");
        }
      }
    },
  });
  const closeModal = () => {
    setSignUpState(false);
    setLoginState(false);
  };
  const openLoginModal = () => {
    setSignUpState(false);
    setLoginState(true);
  };
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (signUpState && e.target.id === "authentication-modal") {
        closeModal();
      }
    };

    if (signUpState) {
      document.body.addEventListener("click", handleOutsideClick);
    } else {
      document.body.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [signUpState]);
  const handleIdentityImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        formik.setFieldValue("identityImage", file);
        setIdentityImageName(file.name);
      } else {
        alert("Please select a valid image file.");
      }
    }
  };

  const handleLicenseImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        formik.setFieldValue("businessLicenseImage", file);
        setBusinessLicenseImageName(file.name);

      } else {
        alert("Please select a valid image file.");
      }
    }
  };

  console.log(formik.errors)
  return (
    <div className="max-w-2xl mx-auto">
      {signUpState && (
        <div
          id="authentication-modal"
          aria-hidden="true"
          className="fixed overflow-x-hidden flex justify-center items-center overflow-y-auto  top-0 left-0 right-0 md:inset-0 z-50"
        >
          <div className="relative w-full max-w-[500px] px-4 h-auto">
            <div className="bg-purple-700 shadow relative   h-full">
              <div className="flex justify-end p-2">
                <button
                  type="button"
                  className="text-gray-400 bg-transparent  hover:text-gray-900 rounded-lg text-lg p-1.5 ml-auto inline-flex items-center  "
                  onClick={closeModal}
                >
                  <svg
                    className="w-7 h-7"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <form
                className="space-y-4 px-6 lg:px-8 pb-4 sm:pb-6 xl:pb-8"
                onSubmit={formik.handleSubmit}
              >
                <h3 className="text-3xl font-medium text-white">Sign in</h3>
                <div className="flex justify-between gap-4 ">
                  <div className="relative">
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      aria-describedby="firstName_text"
                      className="block px-2.5 pb-2.5 pt-5 w-full text-lg text-gray-900 bg-gray-50   border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=""
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                    />
                    <label
                      htmlFor="firstName"
                      className="absolute text-lg text-gray-500 0 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-black   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                    >
                      {formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                        ? `${formik.errors.firstName}`
                        : "First Name"}
                    </label>
                  </div>

                  <div className="relative">
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      aria-describedby="lastName_text"
                      className="block px-2.5 pb-2.5 pt-5 w-full text-lg text-gray-900 bg-gray-50   border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=""
                      onChange={formik.handleChange}
                      value={formik.values.lastName}
                    />
                    <label
                      htmlFor="lastName"
                      className="absolute text-lg text-gray-500   duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-black  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                    >
                      {formik.touched.lastName && Boolean(formik.errors.lastName)
                        ? `${formik.errors.lastName}`
                        : "Last Name"}
                    </label>
                  </div>
                </div>

                <div className="relative">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    aria-describedby="email_text"
                    className="block px-2.5 pb-2.5 pt-5 w-full text-lg text-gray-900 bg-gray-50   border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=""
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  <label
                    htmlFor="email"
                    className="absolute text-lg text-gray-500  duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-black    peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                  >
                    {formik.touched.email && Boolean(formik.errors.email)
                      ? `${formik.errors.email}`
                      : "Email"}
                  </label>
                </div>
                <div className="relative flex items-center">
                  <input
                    name="password"
                    id="password"
                    aria-describedby="password_text"
                    className="block px-2.5 pb-2.5 pt-5 w-full text-lg text-gray-900 bg-gray-50 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=""
                    type={showPassword ? "password" : "text"}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                  <label
                    htmlFor="password"
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                  >
                    {formik.touched.password && Boolean(formik.errors.password)
                      ? `${formik.errors.password}`
                      : "Password"}
                  </label>
                  <div onClick={handleTogglePasswordVisibility} className="absolute right-4 cursor-pointer top-4 text-2xl text-gray-500">
                    {showPassword ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />}
                  </div>
                </div>

                <div className="relative">
                  <input
                    type="text"
                    name="number"
                    id="number"
                    aria-describedby="number_text"
                    className="block px-2.5 pb-2.5 pt-5 w-full text-lg text-gray-900 bg-gray-50  border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=""
                    onChange={formik.handleChange}
                    value={formik.values.number}
                  />
                  <label
                    htmlFor="number"
                    className="absolute text-lg text-gray-500   duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-black   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                  >
                    {formik.touched.number && Boolean(formik.errors.number)
                      ? `${formik.errors.number}`
                      : "Phone Number"}
                  </label>
                </div>
                <div className="flex justify-between">
                  <div className="relative">
                    <p className="font-bold">Identity Card</p>
                    <label htmlFor="identityImage" className="flex gap-3 cursor-pointer">
                      <BsLink45Deg className="text-3xl" />
                      <div className="flex flex-col gap-1">
                        <p className="text-sm text-gray-300">
                          JPG or PNG
                        </p>
                      </div>
                    </label>
                    {formik.touched.identityImage &&
                      Boolean(formik.errors.identityImage) && (
                        <p className="text-lg font-bold text-red-600">
                          {formik.errors.identityImage}
                        </p>
                      )}
                    <input
                      type="file"
                      id="identityImage"
                      accept=".jpg, .jpeg, .png, .gif"
                      onChange={handleIdentityImageChange}
                      style={{ display: "none" }}
                    />
                    {identityImageName ? (
                      <p>Identity Image Uploaded</p>
                    ) : (
                      formik.values.identityImage.length > 0 &&
                      formik.values.identityImage.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))
                    )}
                  </div>

                  <div className="relative">
                    <p className="font-bold">Business License Card</p>
                    <label htmlFor="businessLicenseImage" className="flex gap-3 cursor-pointer">
                      <BsLink45Deg className="text-3xl" />
                      <div className="flex flex-col gap-1">
                        <p className="text-sm text-gray-300">
                          JPG or PNG
                        </p>
                      </div>
                    </label>
                    {formik.touched.businessLicenseImage &&
                      Boolean(formik.errors.businessLicenseImage) && (
                        <p className="text-lg font-bold text-red-600">
                          {formik.errors.businessLicenseImage}
                        </p>
                      )}
                    <input
                      type="file"
                      id="businessLicenseImage"
                      accept=".jpg, .jpeg, .png, .gif"
                      onChange={handleLicenseImageChange}
                      style={{ display: "none" }}
                    />
                     {businessLicenseImageName ? (
                      <p>License Card Uploaded</p>
                    ) : (
                      formik.values.businessLicenseImage.length > 0 &&
                      formik.values.businessLicenseImage.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))
                    )}
                  </div>

                </div>
                <div>
                <button
                  type='submit'
                  className={`w-full text-white bg-darkPurple hover:bg-purple-950 focus:ring-4 font-medium text-lg px-5 py-2.5 text-center ${
                    showSubmitting ? 'opacity-50' : ''
                  }`}
                onClick={handleClick}
                disabled = {showSubmitting}
                >
                  {showSubmitting ? 'Loading...' : 'SignUp'}
                </button>
                </div>
                <div className="text-sm font-mediutext-gray-300 text-center">
                  By selecting
                  <span className="font-bold underline">Sign Up</span> I agree
                  to Tattonight's
                  <span className="underline">Terms of service</span> and
                  <span className="underline">Privacy Policy</span>
                </div>
                <div className="text-sm font-mediutext-gray-300 text-center">
                  Been here before?
                  <span
                    className=" text-base font-bold underline cursor-pointer"
                    onClick={openLoginModal}
                  >
                    Login
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
