import React from "react";
import Modal from "../Modal";
import { FcApproval } from "react-icons/fc";
import ApiService from "../../api/ApiService";
const ApproveModal = ({
  approveModal,
  closeModal,
  valueUpdate,
  fetchDataCall,
}) => {
  const handleApprove = () => {
    if (valueUpdate.status == "Inactive") {
      let activeData = {
        isActive: false,
      };
      const userId = `User/${valueUpdate[0]._id}`;
      ApiService.updateData(userId, activeData).then((res) => {
        if (res) {
          fetchDataCall();
          closeModal();
        }
      });
    } else if (valueUpdate.status == "Approve") {
      const activeData = {
        isActive: true,
      };
      const userId = `User/${valueUpdate[0]._id}`;
      ApiService.updateData(userId, activeData).then((res) => {
        if (res) {
          fetchDataCall();
          closeModal();
        }
      });
    }
  };
  return (
    <>
      <Modal isOpen={approveModal} closeModal={closeModal}>
        <h1 className="text-black font-bold text-center text-xl mb-4">
          {valueUpdate.status}
        </h1>
        <p className="text-black text-5xl animate-pulse flex justify-center  mb-4 mt-2 ">
          <FcApproval />
        </p>
        <p className="text-black mb-4 ">
          Are you sure want to {valueUpdate.status}?
        </p>
        <div className="flex justify-end">
          <button
            onClick={() => handleApprove()}
            className="px-2 py-1 mr-1 rounded text-sm bg-purple-500 hover:bg-purple-700 text-white"
          >
            Yes
          </button>
          <button
            onClick={closeModal}
            className="px-2 py-1 rounded text-sm bg-purple-500 hover:bg-purple-700 text-white"
          >
            No
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ApproveModal;
