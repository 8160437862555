import React, { useEffect, useState } from "react";
import EditModal from "./EditModal/EditModal";
import ApproveModal from "./EditModal/ApproveModal";
import ApiService from "../api/ApiService";
import { MdRemoveRedEye } from "react-icons/md";
import Modal from "./Modal";

const TableData = ({ changeTable, id }) => {
  const [approveModal, setapproveModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [valueUpdate, setValueUpdate] = useState({});
  const [apidata, setApiData] = useState([]);
  const [viewModalValue, setViewModalValue] = useState(false);
  console.log("viewModalValue", viewModalValue)
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setViewModal(false);
  };
  const handleEdit = (id) => {
    console.log("here is the id edit :", id);
    openModal();
    const updateModal = apidata.filter((item) => item._id === id);
    setValueUpdate(updateModal);
  };

  const ApproveCloseModal = () => {
    setapproveModal(false);
  };
  const fetchDataCall = () => {
    const user = "Users";
    ApiService.fetchData(user).then((response) => {
      if (changeTable === "Approved Artists") {
        const filterdata = response.filter((item) => item.isActive === true);
        setApiData(filterdata);
      } else {
        const filterdata = response.filter((item) => item.isActive === false);
        setApiData(filterdata);
      }
    });
  };
  const updateFunction = (data, formdata) => {
    const endpoint = `User/${data}`;
    ApiService.updateData(endpoint, formdata).then((res) => {
      if (res) {
        fetchDataCall();
        closeModal();
      }
    });
  };
  const handleApprove = (id, name) => {
    setapproveModal(true);
    const updateModal = apidata.filter((item) => item._id === id);
    updateModal.status = name;
    setValueUpdate(updateModal);
  };
  const handleInactive = (id, name) => {
    setapproveModal(true);
    const updateModal = apidata.filter((item) => item._id === id);
    updateModal.status = name;
    setValueUpdate(updateModal);
  };
  const handleViewImage = (id) => {
    setViewModal(true);
    const updateModal = apidata.filter((item) => item._id === id);
    setViewModalValue(updateModal);
  };

  useEffect(() => {
    fetchDataCall();
  }, [changeTable]);
  return (
    <>
      <table className="table-auto sm:table-auto w-full border-collapse border border-slate-500">
        <thead>
          <tr>
            <th className="text-start border p-1 border-slate-600">No</th>
            <th className="text-start border p-1 border-slate-600">
              FirstName
            </th>
            <th className="text-start border p-1 border-slate-600">LastName</th>
            <th className="text-start border p-1 border-slate-600">Number</th>
            <th className="text-start border p-1 border-slate-600">Email</th>
            <th className="text-start border p-1 border-slate-600">Edit</th>
            <th className="text-start border p-1 border-slate-600">view</th>
          </tr>
        </thead>
        <tbody>
          {apidata?.map((item, index) => {
            return (
              <tr key={index}>
                <td className="border border-slate-600  p-1">{index + 1}</td>
                <td className="border border-slate-600  p-1">
                  {item.firstName}
                </td>
                <td className="border border-slate-600  p-1">
                  {item.lastName}
                </td>
                <td className="border border-slate-600 p-1">{item.number}</td>
                <td className="border border-slate-600 p-1">{item.email}</td>
                <td className="border border-slate-600 p-1">
                  <button
                    className="text-sm w-full md:w-auto mb-1 md:mb-0 border border-gray-400 px-1 py-1"
                    onClick={() => handleEdit(item._id, "edit")}
                  >
                    Edit
                  </button>
                  {id === "pending" ? (
                    <button
                      onClick={() => handleApprove(item._id, "Approve")}
                      className="text-sm w-full md:w-auto  border bg-purple-500 hover:bg-purple-700 border-gray-400 px-1 py-1 md:ml-1"
                    >
                      Approve
                    </button>
                  ) : (
                    <button
                      onClick={() => handleInactive(item._id, "Inactive")}
                      className="text-sm w-full md:w-auto  border bg-purple-500 hover:bg-purple-700 border-gray-400 px-1 py-1 md:ml-1"
                    >
                      Inactive
                    </button>
                  )}
                </td>
                <td
                  className="border border-slate-600 p-1"
                  onClick={() => handleViewImage(item._id)}
                >
                 <button className="text-sm border border-slate-400 p-1 w-full">View </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal isOpen={viewModal} viewModalValue={viewModalValue} closeModal={closeModal}>
        <div className="h-[50vh] w-[600px] grid grid-cols-2 gap-2">
          <div
            className={`relative group overflow-hidden transition-transform transform-gpu ${isHovered ? "scale-105" : "scale-100"
              }`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >

            <img
              className="h-auto max-w-full rounded-lg"
              src={viewModalValue[0]?.identityImage}
              alt=""
            />
          </div>
          <div
            className={`relative group overflow-hidden transition-transform transform-gpu ${isHovered ? "scale-105" : "scale-100"
              }`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img
              className="h-auto max-w-full rounded-lg"
              // src={item?.businessLicenseImage}
              src={viewModalValue[0]?.businessLicenseImage}

              alt=""
            />
          </div>
        </div>

        <div className="flex justify-end">
          <button
            onClick={closeModal}
            className="px-2 py-1 rounded text-sm bg-purple-500 hover:bg-purple-700 text-white"
          >
            Close
          </button>
        </div>
      </Modal>
      <EditModal
        valueUpdate={valueUpdate}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        setValueUpdate={setValueUpdate}
        updateFunction={updateFunction}
      />
      <ApproveModal
        setValueUpdate={setValueUpdate}
        valueUpdate={valueUpdate}
        approveModal={approveModal}
        closeModal={ApproveCloseModal}
        fetchDataCall={fetchDataCall}
      />
      { }
      <ApproveModal
        setValueUpdate={setValueUpdate}
        valueUpdate={valueUpdate}
        approveModal={approveModal}
        closeModal={ApproveCloseModal}
        fetchDataCall={fetchDataCall}
      />
    </>
  );
};

export default TableData;
