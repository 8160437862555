import React, { useState } from "react";
import { ArtistDashboard } from "./ArtistDashboard";
import { Services } from "./Services";
import { MdSpaceDashboard } from "react-icons/md";
import { FaServicestack } from "react-icons/fa";
import { OrderList } from "./OrderList";
import { FcApproval } from "react-icons/fc";
import { MdOutlinePendingActions } from "react-icons/md";
import './style.css';
import { useSelector } from "react-redux";

export const ArtistProfile = () => {
  const { services } = useSelector((state) => state.serviceDetails)
  const [menuItem, setMenuItem] = useState("dashboard");
  return (
    <>
      <div className="flex">
        <div className="w-[60px] sm:w-[200px] border-r-[1px] border-r-gray-700 md:h-[79vh] ">
          <ul className="p-2">
            <li onClick={() => setMenuItem("dashboard")}>
              <p
                className={`py-1 ${menuItem === "dashboard"
                  ? "border-b-2 border-b-purple-400"
                  : "border-b-2 border-b-transparent"
                  } flex  items-center text-sm mb-2 px-2 cursor-pointer rounded bg-slate-700 hover:bg-slate-800`}
              >
                <span className="sm:block hidden">Dashboard</span>
                <span className="sm:hidden block ">
                  <MdSpaceDashboard />
                </span>
              </p>
            </li>
            <li onClick={() => setMenuItem("services")}>
              <p
                className={`py-1 ${menuItem === "services"
                  ? "border-b-2 border-b-purple-400"
                  : "border-b-2 border-b-transparent"
                  } flex items-center text-sm mb-2 px-2 cursor-pointer rounded bg-slate-700 hover:bg-slate-800`}
              >
                <div className=" flex w-full justify-between">
                  <span className="sm:block hidden">Service</span>
                  <span className="tooltip-container">
                    <span className="flex justify-center items-center">
                      {services[0]?.isActive ?
                        <FcApproval className="text-2xl" />
                        :
                        <MdOutlinePendingActions className="text-2xl" />
                      }
                    </span>
                    <span className="tooltip-text">{services[0]?.isActive ? "Approved" :"Not Approved Yet"}</span>
                  </span>
                </div>
                <span className="sm:hidden block">
                  <FaServicestack />
                </span>
              </p>
            </li>
            <li onClick={() => setMenuItem("orderInfo")}>
              <p
                className={`py-1 ${menuItem === "orderInfo"
                  ? "border-b-2 border-b-purple-400"
                  : "border-b-2 border-b-transparent"
                  } flex items-center text-sm mb-2 px-2 cursor-pointer rounded bg-slate-700 hover:bg-slate-800`}
              >
                <span className="sm:block hidden">Orders</span>
                <span className="sm:hidden block">
                  <FaServicestack />
                </span>
              </p>
            </li>
          </ul>
        </div>
        <div className="w-full p-2  md:h-[79vh] overflow-y-scroll">
          <div className="flex justify-center items-center font-semibold p-2">
            {menuItem == "dashboard" ? (
              <ArtistDashboard />
            ) : menuItem == "services" ? (
              <Services />
            ) : menuItem == "orderInfo" ? (
              <OrderList />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
