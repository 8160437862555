import React, { useEffect, useState } from "react";
import SwitchButton from "../switchButton/SwitchButton";
import Cards from "./Cards";
import LocationMap from "./LocationMap";
import InputSection from "../hero/InputSection";
import { useLocation } from "react-router-dom";
import artistData from "../data/artist_data.json";
import ApiService from "../api/ApiService";

const ArtistCard = () => {
  const location = useLocation();
  const selectedData = location.state;
  const [selectedDate, setSelectedDate] = useState(null);
  const [inputFilter, setInputFilter] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [serviceData, setServiceData] = useState([]);
  const [formattedSelectedDate, setFormattedSelectedDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSortChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
  };
  const handleDropdown = (ele) => {
    setSelectedValue(ele);
    setInputValue(ele);
    setShowDropdown(false);
  };
  const artists = artistData.artists;
  const placeData = artists.map((element) => element.location);
  const handleInput = (event) => {
    const input = event.target.value;
    setInputValue(input);
    const filterResult = placeData.filter((ele) =>
      ele?.toLowerCase().includes(input?.toLowerCase())
    );
    setInputFilter(filterResult);
    setShowDropdown(true);
  };

  useEffect(() => {
    const data = "services";
    ApiService.fetchServiceData(data).then((resp) => {
      setServiceData(resp);
    });
  }, []);
  useEffect(() => {
    setInputValue(selectedData?.inputValue);
    setSelectedDate(selectedData?.selectedDate);
  }, [selectedData]);
  useEffect(() => {
    if (selectedDate) {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      const formattedDate = selectedDate.toLocaleDateString("en-GB", options);
      setFormattedSelectedDate(formattedDate);
    }
  }, [selectedDate]);
  const filteredData =
    serviceData &&
    serviceData.filter((element) => {
      const isInputValueMatch =
        !inputValue ||
        (element.location &&
          element.location.toLowerCase().includes(inputValue.toLowerCase()));
      const isDateMatch =
        !formattedSelectedDate ||
        (element.bookDates
          ? element.bookDates.every((ele) => {
              return ele.date != formattedSelectedDate
                ? true
                : ele.date == formattedSelectedDate && parseInt(ele.value) != 0;
            })
          : true);
      return isInputValueMatch && isDateMatch;
    });
  const sortedData = [...filteredData]; // Create a copy of filteredData
  sortedData.sort((a, b) => {
    console.log("sorteddd data : ", sortedData);
    const rateA = a.price || 0;
    const rateB = b.price || 0;

    if (selectedOption === "highToLow") {
      return rateB - rateA;
    } else if (selectedOption === "lowToHigh") {
      return rateA - rateB;
    }
    return 0; // Default sorting if no option is selected
  });
  return (
    <>
      <div className="lg:flex">
        <div className="lg:w-2/3">
          <div className="w-full md:flex items-center">
            <InputSection
              inputValue={inputValue}
              setShowDropdown={setShowDropdown}
              handleInput={handleInput}
              inputFilter={inputFilter}
              showDropdown={showDropdown}
              handleDropdown={handleDropdown}
              selectedDate={selectedDate}
              handleDateChange={(val) => setSelectedDate(val)}
              placeData={placeData}
            />
            <div className="flex flex-col mx-4 ">
              <select
                className=" rounded-sm block h-[62px] md:mt-3 md:w-[250px]  bg-white text-black transition duration-150 ease-in-out outline-none "
                id="sortSelect"
                value={selectedOption}
                onChange={handleSortChange}
              >
                <option value="">Sorting by price</option>
                <option value="highToLow">High to Low</option>
                <option value="lowToHigh">Low to High</option>
              </select>
            </div>
          </div>
          <div className="border border-gray-500 mx-2 rounded-md my-4 flex justify-between items-center bg-[#2A2B31]  py-6 px-4">
            <div>
              <h1 className="font-semibold">Watch This Search</h1>
              <p className="">We’ll email you pricing updates</p>
            </div>
            <div>
              <SwitchButton />
            </div>
          </div>
          {/* {sortedData
            ?.filter((ele) => ele.isActive)
            .map((element, index) => (
              <Cards element={element} key={index} index={index} />
            ))} */}
            {sortedData
            ?.filter((ele) => ele.isActive)
            .map((element, index) => {
              let totalRating = 0;
              let numberOfReviews = 0;
              if (element.reviewInfo && Array.isArray(element.reviewInfo)) {
                numberOfReviews = element.reviewInfo.length;

                for (const review of element.reviewInfo) {
                  totalRating += review.rating;
                }
              }
              const averageRating = numberOfReviews > 0 ? totalRating / numberOfReviews : 0;
              const ratingPercent = ((averageRating / 5) * 100).toFixed(2);
              const initialRatingPercent = numberOfReviews === 0 ? 100 : ratingPercent;
              element.ratingPercent = initialRatingPercent;
              return <Cards element={element} key={index} index={index} />;
            })}
        </div>
        <LocationMap />
      </div>
    </>
  );
};

export default ArtistCard;
