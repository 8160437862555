import React from "react";

const Modal = ({ isOpen, closeModal, children }) => {
  return isOpen ? (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="modal-bg fixed inset-0 bg-black opacity-50"></div>
      <div className="modal-content p-4 bg-white rounded-lg shadow-lg z-30">
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-gray-600"
        >
          Close
        </button>
        {children}
      </div>
    </div>
  ) : null;
};

export default Modal;
