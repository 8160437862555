import React from "react";
import AppleStore from "../../assets/images/app-store.png";
import PlayStore from "../../assets/images/play-store.png";
import MobileMockup from "../../assets/images/mobile-mockup.png";
import { AiFillStar } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";
export const AppSection = () => {
  return (
    <div className="bg-customPurple">
      <div className="flex flex-col justify-center items-center lg:flex-row xl:flex-row lg:py-20 lg:px-32">
        <div className="flex flex-col justify-start items-start gap-2 p-8 lg:p-4 w-[80%] lg:w-[50%]">
          <p className="text-4xl md:text-5xl font-bold my-2">
            On the go? Get the app.
          </p>
          <p className="text-2xl lg:w-[70%]">
            Want more exclusive rates and features? Yes, yes you do. They’re all
            available in our top-rated app.
          </p>
          <div className="flex flex-col md:flex-row justify-center items-start gap-5 w-full lg:w-3/4  py-4">
            <div className="flex justify-center items-center flex-col w-full  gap-2 lg:w-1/2">
              <img
                className="h-40 w-40 lg:h-20 lg:w-20"
                src={AppleStore}
                alt="Apple store"
              />
              <div className="flex justify-center items-center py-3 text-3xl">
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
              </div>
              <p className="text-xl">+100,000 Reviews</p>
              <p className="font-bold">Apple Essentials</p>
            </div>
            <div className="flex justify-center items-center flex-col w-full gap-2 lg:w-1/2">
              <img
                className="h-40 w-40 lg:h-20 lg:w-20"
                src={PlayStore}
                alt="Apple store"
              />
              <div className="flex justify-center items-center py-3 text-3xl">
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <BsStarHalf />
              </div>
              <p className="text-xl">+50,000 Reviews</p>
              <p className="font-bold">Editor's Choice</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-2 w-[80%] lg:w-[50%]">
          <div className="flex justify-center ml-14  items-end relative">
            <img
              src={MobileMockup}
              alt="Mobile Mockup"
              className="absolute top-5 right-20"
            />
            <img
              height="700px"
              width="450px"
              src={MobileMockup}
              alt="Mobile Mockup"
            />
          </div>
          <div className="cursor-pointer rounded-sm w-[80%] md:w-[70%] lg:w-[70%] xl:w-[70%] bg-darkPurple hover:bg-purple-950  flex justify-center items-center h-10 ">
            <button className="text-xl h-full w-full cursor-pointer appearance-none box-border border-none border-rounded font-semibold uppercase leading-none antialiased m-0 transition duration-125 select-none tracking-wide text-opacity-70">
              GET THE APP
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
