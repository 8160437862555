import React, { useState } from 'react'
import ServiceSetting from './ServiceSetting';
import ServiceProfile from './ServiceProfile';
import { useSelector } from 'react-redux';

export const Services = () => {
    const { services } = useSelector((state) => state.serviceDetails);
  const [showSetting, setShowSetting] = useState(services.length > 0 ? "service" : "setting");
    return (
        <>
            {showSetting == "setting" ? (
                <ServiceSetting setShowSetting={setShowSetting}/>
                ) : showSetting == "service" ? (
                <ServiceProfile setShowSetting={setShowSetting}/>
                ) : null
            }
        </>
    )
}
