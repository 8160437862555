import React, { useEffect, useState } from "react";
import { Login } from "../auth/login/Login";
import { SignUpModal } from "../auth/signUp/SignUpModal";
import { Link } from "react-router-dom";
import AvatarDropdown from "../avatarDropdown/AvatarDropdown";

export const Header = () => {

  const [isHeaderFixed, setIsHeaderFixed] = useState(true);
  const [loginState, setLoginState] = useState(false);
  const [signUpState, setSignUpState] = useState(false);
  const [user, setuser] = useState("");

  useEffect(() => {
    let userData = localStorage.getItem("userData");
    setuser(JSON.parse(userData));
    const handleScroll = () => {
      if (window.scrollY > 40) {
        setIsHeaderFixed(false);
      } else {
        setIsHeaderFixed(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const headerStyles = {
    position: isHeaderFixed ? "static" : "static",
    backgroundColor: isHeaderFixed ? "#1E2128" : "rgba(0, 0, 0, 0.8)",
    transition: "background-color 0.3s ease-in-out",
  };
  return (
    <>
      {loginState && (
        <Login
          loginState={loginState}
          setLoginState={setLoginState}
          setSignUpState={setSignUpState}
        />
      )}
      {signUpState && (
        <SignUpModal
          signUpState={signUpState}
          setSignUpState={setSignUpState}
          setLoginState={setLoginState}
        />
      )}
      <div
        style={headerStyles}
        className="w-full p-4 pt-4 h-16 flex justify-between items-end"
      >
        <Link to="/" className="text-white  text-xl font-bold">
          Tattonight
        </Link>
        <div className="flex justify-center gap-2 items-center">
          <div className="flex justify-center gap-4 items-center">
            {!user ? (
              <>
                <p
                  onClick={() => setLoginState(true)}
                  className="text-white cursor-pointer"
                >
                  Sign In
                </p>
                |
                <p
                  onClick={() => setSignUpState(true)}
                  className="text-white cursor-pointer"
                >
                  Sign Up
                </p>
              </>
            ) : (
              <div className="text-white cursor-pointer px-3 "
              >
                <AvatarDropdown />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
