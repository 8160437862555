// import React from "react";
// import { Navigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

// const ProtectedRoute = ({ isAuthenticated, children }) => {
//   if (!isAuthenticated) {
//     return <Navigate to="/" replace />;
//   }
//   return <>{children}</>;
// };

// export default ProtectedRoute;

export const PrivateOutlet = (props) => {
  const { users, currentUser, loading, handleFetch, error } = useSelector(
    (state) => state.app
  );
  const { children, isAuthentic, redirect = "/" } = props;
  const navigate = useNavigate();
  // const location = useLocation();
  if (loading) {
    return (
      <div className="flex h-[100vh] justify-center items-center">
        {/* <CircularProgress /> */}
        loading
      </div>
    );
  }

  if (!isAuthentic) {
    return <Navigate to={redirect} replace />;
  }
  return <Outlet />;

  // if (!currentUser || currentUser==null) {
  //   return navigate("/");
  // } else if (currentUser.role == "admin") {
  //   return navigate("/dashboard");
  // } else if (currentUser.role == "artist") {
  //   return navigate("/admin-artist");
  // } else {
  //   return <Outlet/>
  // return (
  //   <>
  //     {/* {children} */}
  //     <Outlet />
  //   </>
  // );
  //   }
};
