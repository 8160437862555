import React, { useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const InputSection = ({
  inputFilter,
  inputValue,
  handleDropdown,
  handleInput,
  setShowDropdown,
  showDropdown,
  handleDateChange,
  placeData,
  selectedDate,
}) => {
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowDropdown]);

  return (
    <>
      <div className="w-full md:w-3/5 px-4 py-3 flex flex-col lg:flex-row md:flex-row xl:flex-row justify-center  mt-3 gap-2">
        <div className="rounded-sm group relative border border-blue-400 bg-white p-2 flex w-full lg:w-[50%] xl:w-[50%] flex-col justify-start items-start">
          <p className="text-gray-500 text-sm">Where:</p>
          <input
            type="text"
            className="w-full rounded-sm block bg-white text-black transition duration-150 ease-in-out outline-none"
            placeholder="Cities , Zip code"
            value={inputValue}
            onFocus={() => setShowDropdown(true)}
            onChange={handleInput}
          />
          {inputValue === "" && showDropdown && (
            <div
              ref={dropdownRef}
              className={`min-h-[fit] max-h-[200px] z-10 overflow-y-scroll text-black absolute top-full bg-white w-full left-0`}
            >
              {(inputValue === "" ? placeData : inputFilter)?.map((ele, i) => {
                return (
                  <p
                    key={i}
                    onClick={() => handleDropdown(ele)}
                    className="hover:bg-slate-200 z-20 cursor-pointer text-sm p-2"
                  >
                    {ele}
                  </p>
                );
              })}
            </div>
          )}
        </div>
        <div className="rounded-sm bg-white p-2 w-full lg:w-[50%] flex flex-col justify-start items-start">
          <p className="text-gray-500 text-sm">When:</p>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            isClearable={false}
            className="text-black text-md w-[300px] lg:w-full placeholder:text-gray-400 outline-none"
            placeholderText="Select a Date"
            minDate={new Date()}
          />
        </div>
      </div>
    </>
  );
};

export default InputSection;
