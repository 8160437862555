import React, { useEffect, useState } from "react";
import "./App.css";
import Router from "./router/Router";
import { Header } from "./components/header/Header";
import FooterBar from "./components/footer/FooterBar";
import Dashboard from "./components/dashboard/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { showUser, showUsers } from "./features/userDetailsSlice";
import { Profile } from "./components/profile/Profile";
import { getService, getServices } from "./features/serviceDetailsSlice";

const App = () => {
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState(null);
  const { users, currentUser, loading, handleFetch, error } = useSelector(
    (state) => state.app
  );
  const {
    services,
    singleService,
    serviceLoading,
    serviceError,
    handleFetchServices,
  } = useSelector((state) => state.serviceDetails);
  const id = profileData?.user?.id;
  //Calling Api redux Slice
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      setProfileData(parsedUserData);
    }
    dispatch(showUsers());
    if (id) {
      dispatch(getServices(id));
      dispatch(showUser(id));
    }
  }, [
    handleFetch,
    id,
    // serviceId,
    handleFetchServices,
  ]);
  return (
    <>
      <Header />
      <div className="min-h-[79vh]">
        <Router />
      </div>
      {/* <Profile/> */}
      <FooterBar />
    </>
  );
};

export default App;
