import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BsLink45Deg } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../api/ApiService";
import { IoMdArrowDropdown } from "react-icons/io";
import DatePicker from "react-multi-date-picker";
import { getService, getServices, setHandleFetch } from "../../features/serviceDetailsSlice";

const ServiceSetting = ({ setShowSetting }) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.app);
  const { services } = useSelector((state) => state.serviceDetails);
  const [userFormData, setUserFormData] = useState();
  const [close, setClose] = useState(false);
  const [closeDay, setCloseDay] = useState(false);
  const [closeFacilities, setCloseFacilities] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsDay, setSelectedOptionsDay] = useState([]);
  const [selectedDates, setSelectedDates] = useState(
    services[0]?.unAvailableDates || []
  );
  const [selectedHour, setSelectedHour] = useState(null);
  const [selectedOptionsFacilities, setSelectedOptionsFacilities] = useState(
    []
  );
  const [selectedFacilities, setSelectedFacilities] = useState({
    Pets: false,
    Wifi: false,
    Tv: false,
    Drinks: false,
    Breakfast: false,
    Minibar: false,
    Parking: false,
  });
  const dropdownRef = useRef(null);
  // Step 2: Pass a callback function to the onChange prop of DatePicker
  const handleDateChange = (dates, event) => {
    console.log("dates", dates);
    console.log("event", event);
    setSelectedDates(event.validatedValue);
    console.log("selected", selectedDates);
    servicesFormik.setFieldValue("unAvailableDates", event.validatedValue);
  };
  const options = ["Black Work", "Snakes", "Water", "Dragon", "Horse"];
  const optionDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const optionsFacilities = [
    {
      pets: true,
      wifi: true,
      tv: true,
      drinks: false,
      breakfast: true,
      minibar: true,
      parking: true,
      rooftop_deck: true,
    },
  ];
  const handleCheckboxChange = (optionValue) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(optionValue)) {
        return prevSelectedOptions.filter((value) => value !== optionValue);
      } else {
        return [...prevSelectedOptions, optionValue];
      }
    });
  };

  const handleCheckboxChangeDay = (DayValues) => {
    setSelectedOptionsDay((prevSelectedOptionsDay) => {
      if (prevSelectedOptionsDay.includes(DayValues)) {
        return prevSelectedOptionsDay.filter((value) => value !== DayValues);
      } else {
        return [...prevSelectedOptionsDay, DayValues];
      }
    });
  };

  const handleCheckboxFacilities = (FacilitiesValues) => {
    setSelectedOptionsFacilities((prevSelectedOptionsFacilities) => {
      if (prevSelectedOptionsFacilities.includes(FacilitiesValues)) {
        return prevSelectedOptionsFacilities.filter(
          (value) => value !== FacilitiesValues
        );
      } else {
        return [...prevSelectedOptionsFacilities, FacilitiesValues];
      }
    });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    servicesFormik.setFieldValue("files", selectedFiles);
  };
  console.log("_______________@@@@@@@@@@@@@@@", services);
  const servicesFormik = useFormik({
    initialValues: {
      title: services[0]?.title || "",
      price: services[0]?.price || "",
      unAvailableDates: services[0]?.unAvailableDates || [],
      about: services[0]?.about || "",
      design: services[0]?.design || "",
      unAvailableDays: services[0]?.unAvailableDays || [],
      files: [],
      hours_perday: services[0]?.hours_perday || "",
      specialities: services[0]?.specialities || [],
      facilities: services[0]?.facilities || [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      price: Yup.string().required("Price is required"),
      // specialities: Yup.string().required("Specialities is required"),
      // facilities: Yup.string().required("facilities is required"),
      // quantity: Yup.string().required("Quantity is required"),
      hours_perday: Yup.string().required("hours_perday is required"),
      // about: Yup.string().required("About me is required"),
      // unAvailableDates: Yup.date().required("Available Dates are required"),
      // unAvailableDays: Yup.date().required("Unavailable Dates are required"),
      design: Yup.date().required("design are required"),
      files: Yup.array()
        .of(Yup.mixed())
        .min(3, "Select at least 3 images")
        .max(5, "You can select a maximum of 5 images")
        .required("Files are required"),
    }),

    onSubmit: (values) => {
      console.log("values", values);
      const formData = new FormData();
      values.specialities = selectedOptions;
      values.unAvailableDays = selectedOptionsDay;
      values.facilities = selectedOptionsFacilities;

      // Append text fields
      formData.append("title", values.title);
      formData.append("price", values.price);
      formData.append("about", values.about); // Uncomment if "about" is present
      formData.append("hours_perday", values.hours_perday); // Uncomment if "hours_perday" is present
      formData.append("design", values.design);

      // Append array fields
      formData.append(
        "unAvailableDates",
        (values.unAvailableDates ?? []).join(",")
      );
      formData.append(
        "unAvailableDays",
        (values.unAvailableDays ?? []).join(",")
      );
      formData.append("specialities", (values.specialities ?? []).join(","));
      formData.append("facilities", (values.facilities ?? []).join(","));

      formData.append("userId", currentUser._id); // Convert array to comma-separated string

      // Append file fields
      const selectedFiles = Array.from(values.files);
      selectedFiles.forEach((file, index) => {
        formData.append(`portfolioImages`, file);
      });

      // Log FormData
      for (const pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      if (services.length == 0) {
        const endpoint = "service";
        ApiService.createServiceData(endpoint, formData).then((res) => {
          console.log("theeeeeeeee usere response data :", res);
          dispatch(setHandleFetch());
        });
        setUserFormData(formData);
        setShowSetting("service");
      } else {
        const data = `service/${services[0]?._id}`;
        ApiService.updateServiceData(data, formData).then((res) => {
          console.log("service api response : ", res);
          setShowSetting("service");
          dispatch(getServices(res?.userId));
        });
      }
    },
  });

  const handleopen = () => {
    setClose(!close);
  };
  const handleopenFacilities = () => {
    setCloseFacilities(!closeFacilities);
  };
  const handleopenDay = () => {
    setCloseDay(!closeDay);
  };

  const handleHourChange = (event) => {
    const selectedHourValue = event.target.value;
    setSelectedHour(selectedHourValue);
    servicesFormik.setFieldValue("hours_perday", selectedHourValue);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isTextElement = event.target.closest(".labeltext");

      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !isTextElement &&
        event.target.type !== "checkbox"
      ) {
        setClose(false);
        setCloseDay(false);
        setCloseFacilities(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    setSelectedDates(services[0]?.unAvailableDates);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [services]);

  return (
    <>
      <form onSubmit={servicesFormik.handleSubmit} className="w-full ">
        <div className="md:flex p-4 w-full ">
          <div className=" md:w-3/5 rounded-xl bg-gray-700  md:m-4 p-5">
            <p className="text-2xl py-4 font-bold">Artist Services</p>
            <div className="">
              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-300"
                  >
                    {servicesFormik.touched.title &&
                    Boolean(servicesFormik.errors.title)
                      ? servicesFormik.errors.title
                      : "Title"}
                  </label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    className="bg-gray-50 border border-gray-300 outline-none text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="John"
                    value={servicesFormik.values.title}
                    onChange={servicesFormik.handleChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="price"
                    className="block mb-2 text-sm font-medium text-gray-300"
                  >
                    {servicesFormik.touched.price &&
                    Boolean(servicesFormik.errors.price)
                      ? servicesFormik.errors.price
                      : "Price"}
                  </label>
                  <input
                    type="number"
                    id="price"
                    name="price"
                    className="bg-gray-50 border border-gray-300 outline-none text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Doe"
                    value={servicesFormik.values.price}
                    onChange={servicesFormik.handleChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="unAvailableDates"
                    className="block mb-2 text-sm font-medium text-gray-300"
                  >
                    {servicesFormik.touched.unAvailableDates &&
                    Boolean(servicesFormik.errors.unAvailableDates)
                      ? servicesFormik.errors.unAvailableDates
                      : "Unavailable Dates"}
                  </label>
                  <DatePicker
                    multiple
                    selected={selectedDates}
                    onChange={handleDateChange}
                    placeholder="Enter your dates"
                    style={{ height: "42px", color: "black" }}
                  />
                </div>
                <div>
                  <p className="text-gray-300 text-sm mb-2">Available Hours</p>
                  <div className="rounded-sm bg-white px-2 py-3 w-full flex flex-col justify-start items-start">
                    <select
                      id="hours"
                      name="hours"
                      onChange={handleHourChange}
                      className="bg-gray-50 border border-none border-gray-300 outline-none text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full "
                    >
                      {Array.from({ length: 8 }, (_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1} hours
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="w-full max-w-xs">
                  <label
                    htmlFor="specialities"
                    className="text-gray-300 block text-sm font-medium "
                  >
                    Unavailabel Day
                  </label>
                  <div
                    className="bg-gray-200 relative w-full rounded-md  mt-2"
                    ref={dropdownRef}
                  >
                    <div
                      onClick={handleopenDay}
                      className="flex  justify-between items-center px-2 py-3 cursor-pointer text-black "
                    >
                      <p className="text-sm"> Days</p> <IoMdArrowDropdown />
                    </div>
                    {closeDay && (
                      <div className="bg-gray-600 h-[200px] z-20 overflow-y-auto absolute top-full w-full ">
                        {optionDays.map((day) => (
                          <div key={day}>
                            <label className="labeltext flex items-center border-b border-b-slate-800 p-2">
                              <input
                                type="checkbox"
                                value={day}
                                checked={selectedOptionsDay.includes(day)}
                                onChange={() => handleCheckboxChangeDay(day)}
                              />
                              <p className=" ml-2">{day}</p>
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="design"
                    className="block mb-2 text-sm font-medium text-gray-300"
                  >
                    {servicesFormik.touched.design &&
                    Boolean(servicesFormik.errors.design)
                      ? servicesFormik.errors.design
                      : "Available Design"}
                  </label>
                  <input
                    type="number"
                    id="design"
                    name="design"
                    className="bg-gray-50 border border-gray-300 outline-none text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Design"
                    value={servicesFormik.values.design}
                    onChange={servicesFormik.handleChange}
                  />
                </div>
                <div className="w-full max-w-xs">
                  <label
                    htmlFor="specialities"
                    className="text-gray-300 block text-sm font-medium "
                  >
                    Specialities
                  </label>
                  <div
                    className="bg-gray-200 relative w-full rounded-md  mt-2"
                    ref={dropdownRef}
                  >
                    <div
                      onClick={handleopen}
                      className="flex  justify-between items-center px-2 py-3 cursor-pointer text-black "
                    >
                      <p className="text-sm"> Speciality</p>{" "}
                      <IoMdArrowDropdown />
                    </div>
                    {close && (
                      <div className="bg-gray-600 h-[200px] z-20 overflow-y-auto absolute top-full w-full ">
                        {options.map((option) => (
                          <div key={option}>
                            <label className="labeltext flex items-center border-b border-b-slate-800 p-2">
                              <input
                                type="checkbox"
                                value={option}
                                checked={selectedOptions.includes(option)}
                                onChange={() => handleCheckboxChange(option)}
                              />
                              <p className=" ml-2">{option}</p>
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full max-w-xs">
                  <label
                    htmlFor="specialities"
                    className="text-gray-300 block text-sm font-medium "
                  >
                    Facilities
                  </label>
                  <div
                    className="bg-gray-200 relative w-full rounded-md  mt-2"
                    ref={dropdownRef}
                  >
                    <div
                      onClick={() => handleopenFacilities()}
                      className="flex  justify-between items-center px-2 py-3 cursor-pointer text-black "
                    >
                      <p className="text-sm"> Facilities</p>
                      <IoMdArrowDropdown />
                    </div>
                    {closeFacilities && (
                      <div className="bg-gray-600 h-[200px] z-20 overflow-y-auto absolute  top-full w-full ">
                        {Object.keys(selectedFacilities).map((option) => (
                          <div
                            key={option}
                            className="flex items-center  pl-2 border-b border-b-slate-800"
                          >
                            <input
                              type="checkbox"
                              id={option}
                              name={option}
                              checked={selectedOptions[option]}
                              onChange={() => handleCheckboxFacilities(option)}
                              className="mr-2"
                            />
                            <label
                              htmlFor={option}
                              className="labeltext flex items-center w-full  p-2"
                            >
                              {option}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="about"
                  className="block mb-2 text-sm font-medium text-gray-300"
                >
                  {servicesFormik.touched.about &&
                  Boolean(servicesFormik.errors.about)
                    ? servicesFormik.errors.about
                    : "Bio"}
                </label>
                <textarea
                  type="text"
                  id="about"
                  name="about"
                  className="bg-gray-50 border border-gray-300 outline-none text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Write about your skills and services"
                  rows={4}
                  value={servicesFormik.values.about}
                  onChange={servicesFormik.handleChange}
                />
              </div>
            </div>
            <div className="mt-8">
              {!services[0]?._id ? (
                <button
                  // onClick={handleService}
                  type="submit"
                  className=" text-white bg-purple-800 hover:bg-purple-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  Create Services
                </button>
              ) : (
                <button
                  // onClick={updateService}
                  type="submit"
                  className=" text-white bg-purple-800 hover:bg-purple-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  Update Services
                </button>
              )}
            </div>
          </div>
          <div className="md:w-2/5  flex flex-col justify-start items-center">
            <div className="w-full rounded-xl bg-gray-700  m-4 p-2 flex flex-col justify-start items-center gap-3 pt-16">
              <p className="font-bold">Select Portfolio Images</p>
              <label htmlFor="fileInput" className="flex gap-3 cursor-pointer">
                <BsLink45Deg className="text-5xl" />
                <div className="flex flex-col gap-1">
                  <p className="text-sm">Choose Images</p>
                  <p className="text-sm text-gray-300">
                    JPG, GIF, or PNG. Max size of 800K
                  </p>
                </div>
              </label>
              {servicesFormik.touched.files &&
                Boolean(servicesFormik.errors.files) && (
                  <p className="text-lg text-red-600">
                    {servicesFormik.errors.files}
                  </p>
                )}
              <input
                type="file"
                id="fileInput"
                accept=".jpg, .jpeg, .png, .gif"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              {servicesFormik.values.files.length > 0 && (
                <div className="mt-3">
                  <p>Selected Files:</p>
                  <ul>
                    {servicesFormik.values.files.map((file, index) => (
                      <li key={index}>{file.name}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ServiceSetting;
