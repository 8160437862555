// import React from 'react';
// import {
//   CardNumberElement,
//   CardExpiryElement,
//   CardCvcElement,
//   useElements,
//   useStripe,
// } from '@stripe/react-stripe-js';

// const PaymentForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
  
//     if (!stripe || !elements) {
//       console.error('Stripe.js has not loaded yet.');
//       return;
//     }
  
//     const cardElement = elements.getElement(CardNumberElement);
//     const cardExpiryElement = elements.getElement(CardExpiryElement);
//     const cardCvcElement = elements.getElement(CardCvcElement);
  
//     if (!cardElement || !cardExpiryElement || !cardCvcElement) {
//       console.error('One or more card elements are missing.');
//       return;
//     }
  
//     try {
//       const { error, paymentMethod } = await stripe.createPaymentMethod({
//         type: 'card',
//         card: {
//           number: cardElement,
//           exp_month: cardExpiryElement._firstChild._element,
//           exp_year: cardExpiryElement._secondChild._element,
//           cvc: cardCvcElement,
//         },
//       });
  
//       if (!error) {
//         console.log('PaymentMethod:', paymentMethod);
//         // Handle the payment method, e.g., send it to your server
//       } else {
//         console.error('Error creating payment method:', error);
//         // Handle the error, for example, display an error message to the user
//       }
//     } catch (error) {
//       console.error('An unexpected error occurred:', error);
//       // Handle the unexpected error, for example, display an error message to the user
//     }
//   };
  
  

//      const cardOptions = {
    
//     placeholder: 'Enter Credit Card Number',
//   };

//   return (
//     <>
//       <h1>Card Details</h1>
//       <form onSubmit={handleSubmit} className="max-w-md mx-auto mt-8 p-6 rounded-lg shadow-md">
//         <div className="mb-4">
//           <label className="block text-sm font-semibold mb-2 " htmlFor="card-number">
//             Card Number
//           </label>
//           <CardNumberElement className="w-full p-2 border border-gray-300 rounded bg-white" 
//           options={cardOptions}
//           />
//         </div>
//         <div className="flex space-x-4 mb-4">
//           <div className="flex-1">
//             <label className="block text-sm font-semibold mb-2" htmlFor="card-expiry">
//               Expiry Date
//             </label>
//             <CardExpiryElement className="w-full p-2 border border-gray-300 rounded bg-white" />
//           </div>
//           <div className="flex-1">
//             <label className="block text-sm font-semibold mb-2" htmlFor="card-cvc">
//               CVC
//             </label>
//             <CardCvcElement className="w-full p-2 border border-gray-300 rounded bg-white" />
//           </div>
//         </div>
//         {/* <div className="mb-4">
//           <label className="block text-sm font-semibold mb-2" htmlFor="zip-code">
//             Zip Code
//           </label>
//           <input
//             type="number"
//             id="zip-code"
//             name="zip-code"
//             placeholder='ZIP'
//             className="w-full p-2 rounded text-black bg-white"
//           />
//         </div> */}

//         <button
//           type="submit"
//           className="bg-blue-500 text-white text-sm font-semibold py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
//        >
//           Submit Payment
//         </button>
//       </form>
//     </>
//   );
// };

// export default PaymentForm;

// CheckoutForm.js
import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const CardPayment = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.createToken(elements.getElement(CardElement));

    if (result.error) {
      console.error(result.error.message);
    } else {
      // Send the token to your server or handle it as needed
      console.log(result.token);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Card Number
        <CardElement className="bg-white p-4" />
      </label>
      <button type="submit" className="bg-blue-700 rounded-md" disabled={!stripe}>
        Confirm Payment
      </button>
    </form>
  );
};

export default CardPayment;

