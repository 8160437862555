import React from "react";
import appStore from "../../assets/images/apple.png";
import googleStore from "../../assets/images/google.png";
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiOutlineTwitter,
} from "react-icons/ai";
export const Footer = () => {
  return (
    <>
      <div className=" border-b-2 md:flex">
        <div className="bg-purple-800 md:p-20 p-6 md:w-1/2">
          <h2 className="md:text-7xl text-4xl mb-8  ml-2 font-extrabold drop-shadow-xl">
            TattoNight
          </h2>
          <h3 className="text-2xl md:text-5xl  ml-2 font-bold drop-shadow-xl my-4">
            Plain Less.
          </h3>
          <h3 className="text-2xl md:text-5xl ml-2  font-bold drop-shadow-xl my-4">
            Live More.
          </h3>
          <div className="flex">
            <img
              src={googleStore}
              className=" w-[150px] md:w-[170px]  h-[70px] cursor-pointer"
              alt=""
            />
            <img
              src={appStore}
              className=" w-[150px] md:w-[200px]  h-[60px] mt-[8px] cursor-pointer"
              alt=""
            />
          </div>
        </div>
        <div className="bg-white p-5 md:p-20 md:w-1/2">
          <div className="ml-4 md:ml-32">
            <p className="text-gray-400 cursor-pointer hover:text-gray-500 mt-1 text-xl">
              Our Cities{" "}
            </p>
            <p className="text-gray-400 cursor-pointer hover:text-gray-500 mt-1 text-xl">
              Our Servives{" "}
            </p>
            <p className="text-gray-400 cursor-pointer hover:text-gray-500 mt-1 text-xl">
              About{" "}
            </p>
            <p className="text-gray-400 cursor-pointer hover:text-gray-500 mt-1 text-xl">
              Careers{" "}
            </p>
            <p className="text-gray-400 cursor-pointer hover:text-gray-500 mt-1 text-xl">
              Tatto Partners{" "}
            </p>
            <p className="text-gray-400 cursor-pointer hover:text-gray-500 mt-1 text-xl">
              Supports{" "}
            </p>
            <p className="text-gray-400 cursor-pointer hover:text-gray-500 mt-1 text-xl">
              FAQ{" "}
            </p>

            <p className="text-gray-400 text-sm mt-6">We'are Social</p>
            <p className="text-black w-fit text-sm mt-6 flex  ">
              <AiOutlineInstagram className="text-gray-500 cursor-pointer  m-2 text-4xl" />
              <AiOutlineTwitter className="text-gray-500 cursor-pointer  m-2 text-4xl" />
              <AiOutlineFacebook className="text-gray-500 cursor-pointer  m-2 text-4xl" />
              <AiOutlineLinkedin className="text-gray-500 cursor-pointer  m-2 text-4xl" />
            </p>
          </div>
        </div>
      </div>
    
    </>
  );
};
