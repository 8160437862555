import {
    createAsyncThunk,
    createSlice,
} from "@reduxjs/toolkit";
import { API_BASE_URL } from "../components/api/ApiService";

// Show user
export const getService = createAsyncThunk(
    "getService",
    async (id) => {
        try {
            const res = await fetch(`${API_BASE_URL}/service/${id}`);
            const result = await res.json();
            return result;
        } catch (err) {
            console.log(err);
            return err;
        }
    }
);
export const getServices = createAsyncThunk(
    "getServices",
    async (id) => {
        const res = await fetch(`${API_BASE_URL}/services?userId=${id}`);
        try {
            const result = await res.json();
            console.log(
                "service data in slice :",result
            )
            return result;
        } catch (err) {
            console.log(err);
            return err;
        }
    }
);
export const serviceDetails = createSlice({
    name: "serviceDetails",
    initialState: {
        services: [],
        singleService: null,
        serviceLoading: false,
        serviceError: null,
        handleFetchServices: false,
    },
    reducers: {
        setHandleFetch: (state, action) => {
            state.handleFetchServices = action.payload;
            console.log(action)
        },
    },

    extraReducers: (builder) => {
        //current user
        builder
            .addCase(getService.pending, (state) => {
                state.serviceLoading = true;
            })
            .addCase(getService.fulfilled, (state, action) => {
                state.serviceLoading = false;
                state.singleService = action.payload;
            })
            .addCase(getService.rejected, (state, action) => {
                state.serviceLoading = false;
                state.serviceError = action.error.message;
            });
        //all services 
        builder
            .addCase(getServices.pending, (state) => {
                state.serviceLoading = true;
            })
            .addCase(getServices.fulfilled, (state, action) => {
                state.serviceLoading = false;
                state.services = action.payload;
            })
            .addCase(getServices.rejected, (state, action) => {
                state.serviceLoading = false;
                state.serviceError = action.error.message;
            });
    },
});

export default serviceDetails.reducer;
export const { setHandleFetch } = serviceDetails.actions;
