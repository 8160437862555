import React from "react";
import { AppSection } from "../appSection/AppSection";
import { Hero } from "../hero/Hero";
import { OccasionSection } from "../occasionSection/OccasionSection";
import { ReviewsSection } from "../reviewsSection/ReviewsSection";
import { Footer } from "../footer/Footer";

export const Home = () => {
  return (
    <>
      <Hero />
      <OccasionSection />
      <AppSection />
      <ReviewsSection />
      <Footer />
    </>
  );
};
